import Head from 'next/head';
import useTranslation from 'next-translate/useTranslation';

export default function Meta({
  title,
  description,
  fbShareThumb,
}: {
  title?: string;
  description?: string;
  fbShareThumb?: string;
}) {
  const { t } = useTranslation('common');

  return (
    <Head>
      <title>{title || t('head.default_page_title')}</title>
      <meta name="description" content={description || t('head.default_page_description')} />

      {/* google */}
      <meta itemProp="name" content={title || t('head.default_page_title')} />
      <meta itemProp="description" content={description || t('head.default_page_description')} />

      {/* facebook */}
      <meta
        property="og:title"
        content={title || t('head.default_page_title')}
        itemProp={title ? 'title' : undefined}
      />
      <meta
        property="og:description"
        content={description || t('head.default_page_description')}
        itemProp={description ? 'description' : undefined}
      />
      <meta
        property="og:image"
        content={fbShareThumb || '/media/images/thumbnail-home.png'}
        itemProp={fbShareThumb ? 'image' : undefined}
      />
      <meta property="og:type" content="website" />
    </Head>
  );
}
