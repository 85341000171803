'use client';

import { useCallback, useMemo } from 'react';
import toast, { useToaster, type Toast } from 'react-hot-toast/headless';
import { FloatingPortal } from '@floating-ui/react';
import { m, AnimatePresence } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import type { ToastType, ToastOptions } from './toast';

interface ToastWrapperProps {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  onHeightUpdate: (id: string, height: number) => void;
  children?: React.ReactNode;
}

function ToastWrapper({ id, className, style, onHeightUpdate, children }: ToastWrapperProps) {
  const ref = useCallback(
    (el: HTMLElement | null) => {
      if (el) {
        const updateHeight = () => {
          const { height } = el.getBoundingClientRect();
          onHeightUpdate(id, height);
        };
        updateHeight();
        new MutationObserver(updateHeight).observe(el, {
          subtree: true,
          childList: true,
          characterData: true,
        });
      }
    },
    [id, onHeightUpdate],
  );

  return (
    <div ref={ref} className={className} style={style}>
      {children}
    </div>
  );
}

type ToastPosition =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right';

const prefersReducedMotion = (() => {
  // Cache result
  let shouldReduceMotion: boolean | undefined;

  return () => {
    if (shouldReduceMotion === undefined && typeof window !== 'undefined') {
      const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
      shouldReduceMotion = !mediaQuery || mediaQuery.matches;
    }
    return shouldReduceMotion;
  };
})();

const getPositionStyle = (position: ToastPosition, offset: number): React.CSSProperties => {
  const top = position.includes('top');
  const verticalStyle: React.CSSProperties = top ? { top: 0 } : { bottom: 0 };
  const horizontalStyle: React.CSSProperties = position.includes('center')
    ? {
        justifyContent: 'center',
      }
    : position.includes('right')
    ? {
        justifyContent: 'flex-end',
      }
    : {};
  return {
    left: 0,
    right: 0,
    display: 'flex',
    position: 'absolute',
    willChange: 'transform',
    transition: prefersReducedMotion() ? undefined : `transform 230ms cubic-bezier(.21,1.02,.73,1)`,
    transform: `translateY(${offset * (top ? 1 : -1)}px)`,
    ...verticalStyle,
    ...horizontalStyle,
  };
};

function WarnIcon() {
  return (
    <m.svg
      animate={{ transform: ['scale(0)', 'scale(1)'], opacity: [0, 1] }}
      transition={{ delay: 0.1, duration: 0.3, ease: [0.175, 0.885, 0.32, 1.275] }}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5601 15.2997L13.8901 2.57969C13.2598 1.59369 12.1703 0.99707 11.0001 0.99707C9.82987 0.99707 8.74038 1.59369 8.1101 2.57969L0.440101 15.2997C-0.111353 16.2189 -0.130414 17.3626 0.390101 18.2997C0.992074 19.3548 2.11533 20.0043 3.3301 19.9997H18.6701C19.8766 20.0126 20.9979 19.3794 21.6101 18.3397C22.1462 17.3923 22.1271 16.2289 21.5601 15.2997ZM11.0001 15.9997C10.4478 15.9997 10.0001 15.552 10.0001 14.9997C10.0001 14.4474 10.4478 13.9997 11.0001 13.9997C11.5524 13.9997 12.0001 14.4474 12.0001 14.9997C12.0001 15.552 11.5524 15.9997 11.0001 15.9997ZM11.0001 12.9997C11.5524 12.9997 12.0001 12.552 12.0001 11.9997V7.99969C12.0001 7.44741 11.5524 6.99969 11.0001 6.99969C10.4478 6.99969 10.0001 7.44741 10.0001 7.99969V11.9997C10.0001 12.552 10.4478 12.9997 11.0001 12.9997Z"
        fill="#FFC107"
      />
    </m.svg>
  );
}

function TickIcon() {
  return (
    <m.svg
      animate={{ transform: ['scale(0)', 'scale(1)'], opacity: [0, 1] }}
      transition={{ delay: 0.1, duration: 0.3, ease: [0.175, 0.885, 0.32, 1.275] }}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.47715 4.47715 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM9.73 13.61L14.3 7.61V7.58C14.5179 7.29419 14.5668 6.91382 14.4283 6.58218C14.2897 6.25054 13.9848 6.01801 13.6283 5.97218C13.2718 5.92635 12.9179 6.07419 12.7 6.36L8.92 11.36L7.29 9.28C7.07028 8.99776 6.71668 8.85418 6.36239 8.90334C6.00811 8.9525 5.70696 9.18694 5.57239 9.51834C5.43783 9.84974 5.49028 10.2278 5.71 10.51L8.15 13.62C8.34082 13.8615 8.63222 14.0017 8.94 14C9.2495 13.9993 9.54121 13.8552 9.73 13.61Z"
        fill="#82C160"
      />
    </m.svg>
  );
}

function CircleIcon({ color = '#FF4842' }: { color?: string }) {
  return (
    <m.svg
      animate={{ transform: ['scale(0)', 'scale(1)'], opacity: [0, 1] }}
      transition={{ delay: 0.1, duration: 0.3, ease: [0.175, 0.885, 0.32, 1.275] }}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 15C9.44771 15 9 14.5523 9 14C9 13.4477 9.44771 13 10 13C10.5523 13 11 13.4477 11 14C11 14.5523 10.5523 15 10 15ZM10 12C10.5523 12 11 11.5523 11 11V6C11 5.44772 10.5523 5 10 5C9.44771 5 9 5.44772 9 6V11C9 11.5523 9.44771 12 10 12Z"
        fill={color}
      />
    </m.svg>
  );
}

function CloseIcon() {
  return (
    <svg viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.93909 3.99943L7.80299 1.14179C8.06415 0.880587 8.06415 0.457099 7.80299 0.1959C7.54183 -0.0652999 7.1184 -0.0652999 6.85724 0.1959L4 3.06021L1.14276 0.1959C0.881599 -0.0652999 0.458171 -0.0652999 0.197008 0.1959C-0.0641539 0.457099 -0.0641539 0.880587 0.197008 1.14179L3.06091 3.99943L0.197008 6.85708C0.0709219 6.98215 0 7.15241 0 7.33002C0 7.50763 0.0709219 7.67789 0.197008 7.80296C0.322065 7.92907 0.492298 8 0.669885 8C0.847472 8 1.0177 7.92907 1.14276 7.80296L4 4.93866L6.85724 7.80296C6.9823 7.92907 7.15253 8 7.33011 8C7.5077 8 7.67793 7.92907 7.80299 7.80296C7.92908 7.67789 8 7.50763 8 7.33002C8 7.15241 7.92908 6.98215 7.80299 6.85708L4.93909 3.99943Z"
        fill="#1A3255"
      />
    </svg>
  );
}

function toastTransformTemplate({
  y = 0,
  x = 0,
  z = 0,
  scale = 1,
}: {
  x?: string | number;
  y?: string | number;
  z?: string | number;
  scale?: number;
}) {
  return `translate3d(${x},${y},${z}) scale(${scale})`;
}

type ToastCustom = Toast & ToastOptions & { type: ToastType };

export default function Toaster() {
  const { toasts, handlers } = useToaster({ duration: 5000 });
  const defaultPosition: ToastPosition = 'top-right';

  const { normalToasts, importantToasts } = useMemo(() => {
    return {
      normalToasts: (toasts as ToastCustom[]).filter((t) => !t.important),
      importantToasts: (toasts as ToastCustom[]).filter((t) => t.important),
    };
  }, [toasts]);

  const importantLayout = importantToasts.some((t) => t.visible);

  return (
    <FloatingPortal>
      <div
        style={{
          position: 'fixed',
          inset: 0,
          pointerEvents: importantLayout ? 'auto' : 'none',
          zIndex: 999_999,
        }}
        onMouseEnter={handlers.startPause}
        onMouseLeave={handlers.endPause}
      >
        <AnimatePresence>
          {importantLayout && (
            <m.div
              className="absolute inset-0 bg-black bg-opacity-80"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.1, ease: 'easeOut' } }}
              exit={{ opacity: 0, transition: { duration: 0.15, ease: 'easeIn' } }}
            />
          )}
        </AnimatePresence>

        <ul
          style={{
            position: 'absolute',
            inset: 0,
            marginTop: importantLayout ? 0 : 20,
            marginRight: importantLayout ? 0 : 30,
            marginLeft: importantLayout ? 0 : 30,
          }}
          className="text-[#7A4F01]"
        >
          {(importantLayout ? importantToasts : normalToasts).map((ot) => {
            const t = ot;

            const toastPosition: ToastPosition = t.position || defaultPosition;

            const offset = handlers.calculateOffset(t, {
              reverseOrder: false,
              gutter: 8,
              defaultPosition,
            });

            const positionStyle = getPositionStyle(toastPosition, offset);

            const top = toastPosition.includes('top');
            const factor = top ? 1 : -1;

            const props = t.height
              ? t.visible
                ? {
                    transformTemplate: toastTransformTemplate,
                    animate: {
                      y: [`${factor * -200}%`, '0%'],
                      scale: [0.6, 1],
                      opacity: [0.5, 1],
                    },
                    transition: { duration: 0.35, ease: [0.21, 1.02, 0.73, 1] },
                  }
                : {
                    transformTemplate: toastTransformTemplate,
                    animate: {
                      y: ['0%', `${factor * -150}%`],
                      scale: [1, 0.6],
                      z: -1,
                      opacity: [1, 0],
                    },
                    transition: { duration: 0.4, ease: [0.06, 0.71, 0.55, 1] },
                  }
              : { opacity: 0 };

            const payload = {
              success: {
                icon: <TickIcon />,
                className: 'bg-[#C5E4B7]',
              },
              error: {
                icon: <CircleIcon />,
                className: 'bg-[#FFE7D9]',
              },
              warning: {
                icon: <WarnIcon />,
                className: 'bg-[#FFF7CD]',
              },
              info: {
                icon: <CircleIcon color="#549BFF" />,
                className: 'bg-white',
              },
            };

            const { icon, className } = payload[t.type];

            const dismiss = () => toast.dismiss(t.id);

            return (
              <ToastWrapper
                id={t.id}
                key={t.id}
                onHeightUpdate={handlers.updateHeight}
                className={twMerge(t.visible && 'z-[999999] [&>*]:pointer-events-auto')}
                style={positionStyle}
              >
                <m.div
                  {...props}
                  className={twMerge(
                    importantLayout ? 'w-full' : 'p-[16px] rounded-[8px] min-w-[330px]',
                    className,
                  )}
                >
                  <div
                    className={twMerge(
                      'flex flex-col md:flex-row md:justify-between md:items-center',
                      importantLayout && 'px-[5%] py-[16px]',
                    )}
                  >
                    <div className="w-full">
                      <div className="flex justify-between items-center">
                        <div className="flex items-center">
                          <span className="w-[20px] flex-shrink-0 mr-[10px]">{icon}</span>
                          {t.title ? (
                            <span className="color-[#1A3255] font-bold">{t.title}</span>
                          ) : (
                            <span className="text-[14px]">
                              {typeof t.message === 'function' ? t.message(t) : t.message}
                            </span>
                          )}
                        </div>
                        {!t.rightRender && (
                          <button
                            type="button"
                            className="sm:hidden flex-shrink-0 w-[10px] ml-[10px]"
                            onClick={dismiss}
                          >
                            <CloseIcon />
                          </button>
                        )}
                      </div>
                      {t.title && (
                        <div className="ml-[30px] mr-[20px] sm:mr-0 text-[14px]">
                          {typeof t.message === 'function' ? t.message(t) : t.message}
                        </div>
                      )}
                    </div>
                    {t.rightRender ? (
                      <div className="ml-[30px] mt-[10px] md:mt-0 md:ml-[10px]">
                        {t.rightRender({ dismiss })}
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="hidden sm:block flex-shrink-0 w-[10px] ml-[10px]"
                        onClick={dismiss}
                      >
                        <CloseIcon />
                      </button>
                    )}
                  </div>

                  {importantLayout && (
                    <div className="relative w-full pt-[7px]">
                      <div className="absolute inset-0 py-[1px] bg-[#3E138C]">
                        <div className="h-full bg-[#C790F3]">
                          <div className="h-[83.333333%] bg-[#AA56E5]">
                            <div className="h-[50%] bg-[#893AC0]" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </m.div>
              </ToastWrapper>
            );
          })}
        </ul>
      </div>
    </FloatingPortal>
  );
}
