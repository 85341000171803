import { createContext } from 'react';
import {
  AuthProviderViewerSessionQueryQuery,
  LearnerProfileFragment,
  ViewerFragment,
} from '@/__generated__/graphql';

export interface AuthContext {
  user?: ViewerFragment | null;
  currentLearnerProfile?: LearnerProfileFragment | null;
  currentLearnerProfileId?: string;
  learnerProfiles: readonly LearnerProfileFragment[];
  loading: boolean;
  isInitialized: boolean;
  logOut: () => Promise<void>;
  switchLearnerProfile: (id: string) => Promise<void>;
  writeQueryViewer: (queryViewer: AuthProviderViewerSessionQueryQuery) => void;
  getCurrentLearnerProfileId: (name?: string) => Promise<string | undefined>;
  reFetchViewer: () => Promise<void>;
  isLogin: boolean;
  isFlyerUs: boolean;
}

export default createContext<AuthContext | undefined>(undefined);
