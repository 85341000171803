// import type { Level } from '@/components/exam/ExamHomeView/ExamFooter/ProgressBar';
// import flyerImage from '@/components/exam/ExamHomeView/assets/exam-planet/FLYERS.png';
// import ieltsImage from '@/components/exam/ExamHomeView/assets/exam-planet/IELTS.png';
// import ioeImage from '@/components/exam/ExamHomeView/assets/exam-planet/IOE.png';
// import ketImage from '@/components/exam/ExamHomeView/assets/exam-planet/KET.png';
// import moversImage from '@/components/exam/ExamHomeView/assets/exam-planet/MOVERS.png';
// import petImage from '@/components/exam/ExamHomeView/assets/exam-planet/PET.png';
// import phaDaoImage from '@/components/exam/ExamHomeView/assets/exam-planet/Pha-dao.png';
// import setImage from '@/components/exam/ExamHomeView/assets/exam-planet/SET.png';
// import starterImage from '@/components/exam/ExamHomeView/assets/exam-planet/STARTERS.png';
// import toeflJuniorImage from '@/components/exam/ExamHomeView/assets/exam-planet/TOEFL-JUNIOR.png';
// import PlanetsToeflPrimaryImage from '@/components/exam/ExamHomeView/assets/exam-planet/TOEFL-PRIMARY.png';
//
// import ribbonPlanetFlyer from '@/components/exam/ExamHomeView/assets/ribbon/TOEFL-PRIMARY.png';
// import ribbonStarter from '@/components/exam/ExamHomeView/assets/ribbon/STARTERS.png';
// import ribbonMovers from '@/components/exam/ExamHomeView/assets/ribbon/MOVERS.png';
// import ribbonFlyers from '@/components/exam/ExamHomeView/assets/ribbon/FLYERS.png';
// import ribbonToeflJunior from '@/components/exam/ExamHomeView/assets/ribbon/TOEFL-JUNIOR.png';
// import ribbonKet from '@/components/exam/ExamHomeView/assets/ribbon/KET.png';
// import ribbonPet from '@/components/exam/ExamHomeView/assets/ribbon/PET.png';
// import ribbonIOE from '@/components/exam/ExamHomeView/assets/ribbon/IOE.png';
// import ribbonSet from '@/components/exam/ExamHomeView/assets/ribbon/SET.png';
// import ribbonIelts from '@/components/exam/ExamHomeView/assets/ribbon/IELTS.png';
// import ribbonPd from '@/components/exam/ExamHomeView/assets/ribbon/Pha-dao.png';
//
// import A1 from '@/components/container/modal/PlacementTestModal/assets/testResult/A1.png';
// import A2 from '@/components/container/modal/PlacementTestModal/assets/testResult/A2.png';
// import B1 from '@/components/container/modal/PlacementTestModal/assets/testResult/B1.png';
// import B2 from '@/components/container/modal/PlacementTestModal/assets/testResult/B2.png';
// import C1 from '@/components/container/modal/PlacementTestModal/assets/testResult/C1.png';
// import C2 from '@/components/container/modal/PlacementTestModal/assets/testResult/C2.png';
// import PreA1 from '@/components/container/modal/PlacementTestModal/assets/testResult/preA1.png';
//
// export const dataHome = [
//   {
//     id: '',
//     slug: 'toefl-primary',
//     animateKey: '1',
//     dotIcon: '/media/images/image_slide/icons-dot-toelf.png',
//     ribbon: ribbonPlanetFlyer,
//     getUris: () =>
//       import('@/components/exam/ExamHomeView/assets/animation/planets-toefl-primary.json'),
//     image: PlanetsToeflPrimaryImage,
//     tag: ['prea1', 'a1', 'a2', 'b1', 'ets'],
//     bgForBottom: '',
//     name: 'TOEFL PRIMARY',
//     description: ('' as string) || null,
//     colorBorder: '',
//     descriptionEn: ('' as string) || null,
//     colorDotIcon: '',
//     active: true,
//     hidden: [],
//   },
//   {
//     id: '',
//     slug: 'starters',
//     dotIcon: '/media/images/image_slide/STARTERS.png',
//     ribbon: ribbonStarter,
//     animateKey: '2',
//     getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-starter.json'),
//     image: starterImage,
//     tag: ['prea1', 'cambridge'],
//     bgForBottom: '',
//     name: 'Starters',
//     description: ('' as string) || null,
//     colorBorder: '',
//     descriptionEn: ('' as string) || null,
//     colorDotIcon: '',
//     active: true,
//     hidden: [],
//   },
//   {
//     id: '',
//     slug: 'movers',
//     dotIcon: '/media/images/image_slide/MOVERS.png',
//     ribbon: ribbonMovers,
//     animateKey: '3',
//     getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-movers.json'),
//     image: moversImage,
//     tag: ['a1', 'cambridge'],
//     bgForBottom: '',
//     name: 'Movers',
//     description: ('' as string) || null,
//     colorBorder: '',
//     descriptionEn: ('' as string) || null,
//     colorDotIcon: '',
//     active: true,
//     hidden: [],
//   },
//   {
//     id: '',
//     slug: 'flyers',
//     dotIcon: '/media/images/image_slide/FLYERS.png',
//     ribbon: ribbonFlyers,
//     animateKey: '4',
//     getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-flyer.json'),
//     image: flyerImage,
//     tag: ['a2', 'cambridge'],
//     bgForBottom: '',
//     name: 'Flyers',
//     description: ('' as string) || null,
//     colorBorder: '',
//     descriptionEn: ('' as string) || null,
//     colorDotIcon: '',
//     active: true,
//     hidden: [],
//   },
//   {
//     id: '',
//     slug: 'toefl-junior',
//     dotIcon: '/media/images/image_slide/TOEFL JUNIOR.png',
//     ribbon: ribbonToeflJunior,
//     animateKey: '11',
//     getUris: () =>
//       import('@/components/exam/ExamHomeView/assets/animation/planets-toefl-junior.json'),
//     image: toeflJuniorImage,
//     tag: ['a2', 'b1', 'b2', 'ets'],
//     bgForBottom: '',
//     name: 'Toefl Junior',
//     description: ('' as string) || null,
//     colorBorder: '',
//     descriptionEn: ('' as string) || null,
//     colorDotIcon: '',
//     active: true,
//     hidden: [],
//   },
//   {
//     id: '',
//     slug: 'ket',
//     dotIcon: '/media/images/image_slide/KET.png',
//     ribbon: ribbonKet,
//     animateKey: '6',
//     getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-ket.json'),
//     image: ketImage,
//     tag: ['a2', 'cambridge'],
//     bgForBottom: '',
//     name: 'KET',
//     description: ('' as string) || null,
//     colorBorder: '',
//     descriptionEn: ('' as string) || null,
//     colorDotIcon: '',
//     active: true,
//     hidden: [],
//   },
//   {
//     id: '',
//     slug: 'pet',
//     dotIcon: '/media/images/image_slide/PET.png',
//     ribbon: ribbonPet,
//     animateKey: '5',
//     getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-pet.json'),
//     image: petImage,
//     tag: ['b1', 'cambridge'],
//     bgForBottom: '',
//     name: 'PET',
//     description: ('' as string) || null,
//     colorBorder: '',
//     descriptionEn: ('' as string) || null,
//     colorDotIcon: '',
//     active: true,
//     hidden: [],
//   },
//   {
//     id: '',
//     slug: 'ioe',
//     dotIcon: '/media/images/image_slide/IOE.png',
//     ribbon: ribbonIOE,
//     animateKey: '9',
//     getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-ioe.json'),
//     image: ioeImage,
//     tag: ['a1', 'a2', 'school'],
//     bgForBottom: '',
//     name: 'IOE',
//     description: ('' as string) || null,
//     colorBorder: '',
//     descriptionEn: ('' as string) || null,
//     colorDotIcon: '',
//     active: true,
//     hidden: ['en'],
//   },
//   {
//     id: '',
//     slug: 'set',
//     dotIcon: '/media/images/image_slide/SET.png',
//     ribbon: ribbonSet,
//     animateKey: '8',
//     getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-set.json'),
//     image: setImage,
//     tag: ['a1', 'school'],
//     bgForBottom: '',
//     name: 'SET',
//     description: ('' as string) || null,
//     colorBorder: '',
//     descriptionEn: ('' as string) || null,
//     colorDotIcon: '',
//     active: true,
//     hidden: ['en'],
//   },
//   {
//     id: '',
//     slug: 'ielts',
//     dotIcon: '/media/images/image_slide/X-planet.png',
//     ribbon: ribbonIelts,
//     animateKey: '10',
//     getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-ielts.json'),
//     image: ieltsImage,
//     tag: ['b1', 'b2', 'c1', 'c2'],
//     bgForBottom: '',
//     name: 'IELTS',
//     description: ('' as string) || null,
//     colorBorder: '',
//     descriptionEn: ('' as string) || null,
//     colorDotIcon: '',
//     active: true,
//     hidden: [],
//   },
//   {
//     id: '',
//     slug: 'pha-dao',
//     dotIcon: '/media/images/image_slide/X-planet.png',
//     ribbon: ribbonPd,
//     animateKey: '7',
//     getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-pha-dao.json'),
//     image: phaDaoImage,
//     tag: ['a1', 'a2', 'b1', 'flyer'],
//     bgForBottom: '',
//     name: 'Phá đảo',
//     description: ('' as string) || null,
//     colorBorder: '',
//     descriptionEn: ('' as string) || null,
//     colorDotIcon: '',
//     active: true,
//     hidden: ['vi', 'en'],
//   },
// ];
//
// export const dataScoreRank: Level[] = [
//   {
//     title: 'PRE A1',
//     value: 'prea1',
//     color: 'ocean-green',
//     hashColor: '#47C199',
//     Src: PreA1,
//   },
//   {
//     title: 'A1',
//     value: 'a1',
//     color: 'orange',
//     hashColor: '#F48221',
//     Src: A1,
//   },
//   {
//     title: 'A2',
//     value: 'a2',
//     color: 'orange-soda',
//     hashColor: '#F95F3C',
//     Src: A2,
//   },
//   {
//     title: 'B1',
//     value: 'b1',
//     color: 'green',
//     hashColor: '#33CF4B',
//     Src: B1,
//   },
//   {
//     title: 'B2',
//     value: 'b2',
//     color: 'blue',
//     hashColor: '#4C70E8',
//     Src: B2,
//   },
//   {
//     title: 'C1',
//     value: 'c1',
//     color: 'teal',
//     hashColor: '#4FBAAD',
//     Src: C1,
//   },
//   {
//     title: 'C2',
//     value: 'c2',
//     color: 'dark-orchid',
//     hashColor: '#AD2CCC',
//     Src: C2,
//   },
// ];
//
// export const dataCambridge = [
//   {
//     id: '',
//     title: 'all',
//   },
//   {
//     id: 'cambridge',
//     title: 'cambridge',
//   },
//   {
//     id: 'school',
//     title: 'school_cambridge',
//   },
//   // {
//   //   id: 'flyer',
//   //   title: 'flyer',
//   // },
//   {
//     id: 'ets',
//     title: 'toefl',
//   },
// ];

import type { Level } from '@/components/exam/ExamHomeView/ExamFooter/ProgressBar';
import flyerImage from '@/components/exam/ExamHomeView/assets/exam-planet/FLYERS.png';
import ieltsImage from '@/components/exam/ExamHomeView/assets/exam-planet/IELTS.png';
import ioeImage from '@/components/exam/ExamHomeView/assets/exam-planet/IOE.png';
import ketImage from '@/components/exam/ExamHomeView/assets/exam-planet/KET.png';
import moversImage from '@/components/exam/ExamHomeView/assets/exam-planet/MOVERS.png';
import petImage from '@/components/exam/ExamHomeView/assets/exam-planet/PET-1.png';
import setImage from '@/components/exam/ExamHomeView/assets/exam-planet/SET.png';
import starterImage from '@/components/exam/ExamHomeView/assets/exam-planet/STARTERS.png';
import toeflJuniorImage from '@/components/exam/ExamHomeView/assets/exam-planet/TOEFL-JUNIOR.png';
import setJuniorImage from '@/components/exam/ExamHomeView/assets/exam-planet/SET-JUNIOR.png';
import PlanetsToeflPrimaryImage from '@/components/exam/ExamHomeView/assets/exam-planet/TOEFL-PRIMARY.png';

import ribbonPlanetFlyer from '@/components/exam/ExamHomeView/assets/ribbon/TOEFL-PRIMARY.png';
import ribbonStarter from '@/components/exam/ExamHomeView/assets/ribbon/STARTERS.png';
import ribbonMovers from '@/components/exam/ExamHomeView/assets/ribbon/MOVERS.png';
import ribbonFlyers from '@/components/exam/ExamHomeView/assets/ribbon/FLYERS.png';
import ribbonToeflJunior from '@/components/exam/ExamHomeView/assets/ribbon/TOEFL-JUNIOR.png';
import ribbonKet from '@/components/exam/ExamHomeView/assets/ribbon/KET.png';
import ribbonPet from '@/components/exam/ExamHomeView/assets/ribbon/PET-1.png';
import ribbonIOE from '@/components/exam/ExamHomeView/assets/ribbon/IOE-1.png';
import ribbonSet from '@/components/exam/ExamHomeView/assets/ribbon/SET.png';
import ribbonIelts from '@/components/exam/ExamHomeView/assets/ribbon/IELTS.png';
import ribbonSetJunior from '@/components/exam/ExamHomeView/assets/ribbon/SET-JUNIOR.png';

import A1 from '@/components/container/modal/PlacementTestModal/assets/testResult/A1.png';
import A2 from '@/components/container/modal/PlacementTestModal/assets/testResult/A2.png';
import B1 from '@/components/container/modal/PlacementTestModal/assets/testResult/B1.png';
import B2 from '@/components/container/modal/PlacementTestModal/assets/testResult/B2.png';
import C1 from '@/components/container/modal/PlacementTestModal/assets/testResult/C1.png';
import C2 from '@/components/container/modal/PlacementTestModal/assets/testResult/C2.png';
import PreA1 from '@/components/container/modal/PlacementTestModal/assets/testResult/preA1.png';

const testLevels = [
  {
    localId: '0',
    slug: 'toefl-primary',
    animateKey: '1',
    dotIcon: '/media/images/image_slide/icons-dot-toelf.png',
    ribbon: ribbonPlanetFlyer,
    getUris: () =>
      import('@/components/exam/ExamHomeView/assets/animation/planets-toefl-primary.json'),
    image: PlanetsToeflPrimaryImage,
    tag: ['prea1', 'a1', 'a2', 'b1', 'ets'],
    bgForBottom: '',
    name: 'TOEFL PRIMARY',
    description: ('' as string) || null,
    colorBorder: '',
    descriptionEn: ('' as string) || null,
    colorDotIcon: '',
    active: true,
    hidden: [] as string[],
  },
  {
    localId: '1',
    slug: 'starters',
    dotIcon: '/media/images/image_slide/STARTERS.png',
    ribbon: ribbonStarter,
    animateKey: '2',
    getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-starter.json'),
    image: starterImage,
    tag: ['prea1', 'cambridge'],
    bgForBottom: '',
    name: 'Starters',
    description: ('' as string) || null,
    colorBorder: '',
    descriptionEn: ('' as string) || null,
    colorDotIcon: '',
    active: true,
    hidden: [] as string[],
  },
  {
    localId: '2',
    slug: 'movers',
    dotIcon: '/media/images/image_slide/MOVERS.png',
    ribbon: ribbonMovers,
    animateKey: '3',
    getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-movers.json'),
    image: moversImage,
    tag: ['a1', 'cambridge'],
    bgForBottom: '',
    name: 'Movers',
    description: ('' as string) || null,
    colorBorder: '',
    descriptionEn: ('' as string) || null,
    colorDotIcon: '',
    active: true,
    hidden: [] as string[],
  },
  {
    localId: '3',
    slug: 'flyers',
    dotIcon: '/media/images/image_slide/FLYERS.png',
    ribbon: ribbonFlyers,
    animateKey: '4',
    getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-flyer.json'),
    image: flyerImage,
    tag: ['a2', 'cambridge'],
    bgForBottom: '',
    name: 'Flyers',
    description: ('' as string) || null,
    colorBorder: '',
    descriptionEn: ('' as string) || null,
    colorDotIcon: '',
    active: true,
    hidden: [] as string[],
  },
  {
    localId: '4',
    slug: 'toefl-junior',
    dotIcon: '/media/images/image_slide/TOEFL JUNIOR.png',
    ribbon: ribbonToeflJunior,
    animateKey: '11',
    getUris: () =>
      import('@/components/exam/ExamHomeView/assets/animation/planets-toefl-junior.json'),
    image: toeflJuniorImage,
    tag: ['a2', 'b1', 'b2', 'ets'],
    bgForBottom: '',
    name: 'Toefl Junior',
    description: ('' as string) || null,
    colorBorder: '',
    descriptionEn: ('' as string) || null,
    colorDotIcon: '',
    active: true,
    hidden: [] as string[],
  },
  {
    localId: '5',
    slug: 'ket',
    dotIcon: '/media/images/image_slide/KET.png',
    ribbon: ribbonKet,
    animateKey: '6',
    getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-ket.json'),
    image: ketImage,
    tag: ['a2', 'cambridge'],
    bgForBottom: '',
    name: 'KET',
    description: ('' as string) || null,
    colorBorder: '',
    descriptionEn: ('' as string) || null,
    colorDotIcon: '',
    active: true,
    hidden: [] as string[],
  },
  {
    localId: '6',
    slug: 'pet',
    dotIcon: '/media/images/image_slide/PET.png',
    ribbon: ribbonPet,
    animateKey: '5',
    getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-pet-1.json'),
    image: petImage,
    tag: ['b1', 'cambridge'],
    bgForBottom: '',
    name: 'PET',
    description: ('' as string) || null,
    colorBorder: '',
    descriptionEn: ('' as string) || null,
    colorDotIcon: '',
    active: true,
    hidden: [] as string[],
  },
  {
    localId: '7',
    slug: 'ioe',
    dotIcon: '/media/images/image_slide/IOE.png',
    ribbon: ribbonIOE,
    animateKey: '9',
    getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-ioe.json'),
    image: ioeImage,
    tag: ['a1', 'a2', 'school'],
    bgForBottom: '',
    name: 'IOE',
    description: ('' as string) || null,
    colorBorder: '',
    descriptionEn: ('' as string) || null,
    colorDotIcon: '',
    active: true,
    hidden: [] as string[],
  },
  {
    localId: '8',
    slug: 'set',
    dotIcon: '/media/images/image_slide/SET.png',
    ribbon: ribbonSet,
    animateKey: '8',
    getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-set.json'),
    image: setImage,
    tag: ['a1', 'school'],
    bgForBottom: '',
    name: 'SET',
    description: ('' as string) || null,
    colorBorder: '',
    descriptionEn: ('' as string) || null,
    colorDotIcon: '',
    active: true,
    hidden: [] as string[],
  },
  {
    localId: '9',
    slug: 'ielts',
    dotIcon: '/media/images/image_slide/X-planet.png',
    ribbon: ribbonIelts,
    animateKey: '10',
    getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-ielts.json'),
    image: ieltsImage,
    tag: ['b1', 'b2', 'c1', 'c2'],
    bgForBottom: '',
    name: 'IELTS',
    description: ('' as string) || null,
    colorBorder: '',
    descriptionEn: ('' as string) || null,
    colorDotIcon: '',
    active: true,
    hidden: ['vi', 'en'] as string[],
  },
  // {
  //   localId: '10',
  //   slug: 'pha-dao',
  //   dotIcon: '/media/images/image_slide/X-planet.png',
  //   ribbon: ribbonPd,
  //   animateKey: '7',
  //   getUris: () => import('@/components/exam/ExamHomeView/assets/animation/planets-pha-dao.json'),
  //   image: phaDaoImage,
  //   tag: ['a1', 'a2', 'b1', 'flyer'],
  //   bgForBottom: '',
  //   name: 'Phá đảo',
  //   description: ('' as string) || null,
  //   colorBorder: '',
  //   descriptionEn: ('' as string) || null,
  //   colorDotIcon: '',
  //   active: true,
  //   hidden: ['vi', 'en'] as string[],
  // },
  {
    localId: '11',
    slug: 'set-junior',
    dotIcon: '/media/images/image_slide/X-planet.png',
    ribbon: ribbonSetJunior,
    animateKey: '7',
    getUris: () =>
      import('@/components/exam/ExamHomeView/assets/animation/planets-set-junior.json'),
    image: setJuniorImage,
    tag: ['a2', 'b1'],
    bgForBottom: '',
    name: 'SET Junior',
    description: ('' as string) || null,
    colorBorder: '',
    descriptionEn: ('' as string) || null,
    colorDotIcon: '',
    active: true,
    hidden: [] as string[],
  },
];

export const dataHome = [testLevels].flatMap((tls, i) =>
  tls.map((t) => ({ ...t, localId: `${i}${t.localId}`, isDuplicated: i !== 0 })),
);

export const dataScoreRank: Level[] = [
  {
    title: 'PRE A1',
    value: 'prea1',
    color: 'ocean-green',
    hashColor: '#47C199',
    Src: PreA1,
  },
  {
    title: 'A1',
    value: 'a1',
    color: 'orange',
    hashColor: '#F48221',
    Src: A1,
  },
  {
    title: 'A2',
    value: 'a2',
    color: 'orange-soda',
    hashColor: '#F95F3C',
    Src: A2,
  },
  {
    title: 'B1',
    value: 'b1',
    color: 'green',
    hashColor: '#33CF4B',
    Src: B1,
  },
  {
    title: 'B2',
    value: 'b2',
    color: 'blue',
    hashColor: '#4C70E8',
    Src: B2,
  },
  {
    title: 'C1',
    value: 'c1',
    color: 'teal',
    hashColor: '#4FBAAD',
    Src: C1,
  },
  {
    title: 'C2',
    value: 'c2',
    color: 'dark-orchid',
    hashColor: '#AD2CCC',
    Src: C2,
  },
];

export const dataCambridge = [
  {
    id: 'c2',
    title: 'c2',
  },
  {
    id: 'c1',
    title: 'c1',
  },
  {
    id: 'b2',
    title: 'b2',
  },
  {
    id: 'b1',
    title: 'b1',
  },
  {
    id: 'a2',
    title: 'a2',
  },
  {
    id: 'a1',
    title: 'a1',
  },
  {
    id: 'prea1',
    title: 'pre A1',
  },
  {
    id: '',
    title: 'all',
  },
];
