// Code generated by graphql-codegen. DO NOT EDIT.
import type * as Types from './types';

import { gql } from '@apollo/client';
export type Checkpoint = { id: string, name: string, nameEn?: string | null };

export type WritingCheckPoint = { testLevelId?: string | null, position: number, checkPoint: { id: string, name: string, nameEn?: string | null }, parts: Array<{ id: string, title?: string | null, position: number, images: Array<{ partId: string, position: number, image: { id: string, label?: string | null, blurHash?: string | null } }> }> };

export type ExamQuestion = { id: string, name: string, title: string, examType: Types.ExamTypesEnum, questionType: Types.QuestionTypesEnum, maxScore: number, contents: Array<{ id: string, content: Record<string, unknown> | Array<unknown> }>, subquestions: Array<{ id: string, title?: string | null, choices?: Record<string, unknown> | Array<unknown> | null, content?: Record<string, unknown> | Array<unknown> | null, shuffle: boolean, order: number, titlePrefix?: Record<string, unknown> | Array<unknown> | null, detailPrefix?: Record<string, unknown> | Array<unknown> | null, isExample: boolean, details: Array<{ id: string, detail?: Record<string, unknown> | Array<unknown> | null, prefix?: string | null, order: number, result: Record<string, unknown> | Array<unknown>, example?: { result?: Record<string, unknown> | Array<unknown> | null } | null }> }>, mark?: { total?: string | null } | null };

export type TestLevel = { id: string, name: string, slug: string };

export type ExamCheckPoint = { id: string, name: string, nameEn?: string | null, testLevel?: { id: string, name: string, slug: string, examSettings: Array<{ examType: Types.ExamTypesEnum, executionTime: string }> } | null, cpQuestions: Array<{ question: { id: string, name: string, title: string, examType: Types.ExamTypesEnum, questionType: Types.QuestionTypesEnum, maxScore: number, contents: Array<{ id: string, content: Record<string, unknown> | Array<unknown> }>, subquestions: Array<{ id: string, title?: string | null, choices?: Record<string, unknown> | Array<unknown> | null, content?: Record<string, unknown> | Array<unknown> | null, shuffle: boolean, order: number, titlePrefix?: Record<string, unknown> | Array<unknown> | null, detailPrefix?: Record<string, unknown> | Array<unknown> | null, isExample: boolean, details: Array<{ id: string, detail?: Record<string, unknown> | Array<unknown> | null, prefix?: string | null, order: number, result: Record<string, unknown> | Array<unknown>, example?: { result?: Record<string, unknown> | Array<unknown> | null } | null }> }>, mark?: { total?: string | null } | null } }> };

export type ExamStructure = { order: number, displayName: string, examType: Types.ExamTypesEnum };

export type ExamQuestionAttempt = { id: string, questionId?: string | null, details: Array<{ id: string, answer?: Record<string, unknown> | Array<unknown> | null, result: Record<string, unknown> | Array<unknown>, questionDetailId: string, isCorrect: boolean }> };

export const Checkpoint = gql`
    fragment Checkpoint on check_points {
  id
  name
  nameEn: name_en
}
    `;
export const WritingCheckPoint = gql`
    fragment WritingCheckPoint on writing_check_points {
  checkPoint {
    ...Checkpoint
  }
  testLevelId
  position
  parts: writingParts(order_by: {position: asc}) {
    id
    title
    position
    images(order_by: {position: asc}) {
      partId
      position
      image {
        id
        label
        blurHash: blur_hash
      }
    }
  }
}
    ${Checkpoint}`;
export const TestLevel = gql`
    fragment TestLevel on test_levels {
  id
  name
  slug
}
    `;
export const ExamQuestion = gql`
    fragment ExamQuestion on questions {
  id
  name
  title
  examType: exam_type
  questionType: question_type
  contents {
    id
    content
  }
  subquestions(order_by: {order: asc}) {
    id
    title
    titlePrefix: title_prefix
    detailPrefix: detail_prefix
    choices
    content
    shuffle
    isExample: is_example
    order
    details(order_by: {order: asc}) {
      id
      detail
      example {
        result
      }
      prefix
      order
      result
    }
  }
  mark {
    total
  }
  maxScore: max_score
}
    `;
export const ExamCheckPoint = gql`
    fragment ExamCheckPoint on check_points {
  ...Checkpoint
  testLevel: test_level {
    ...TestLevel
    examSettings: exam_settings {
      examType: exam_type
      executionTime: execution_time
    }
  }
  cpQuestions: cp_questions {
    question {
      ...ExamQuestion
    }
  }
}
    ${Checkpoint}
${TestLevel}
${ExamQuestion}`;
export const ExamStructure = gql`
    fragment ExamStructure on exam_structures {
  displayName: display_name
  examType: exam_type
  order
}
    `;
export const ExamQuestionAttempt = gql`
    fragment ExamQuestionAttempt on question_attempts {
  id
  questionId: question_id
  details {
    id
    questionDetailId: question_detail_id
    answer
    result
    isCorrect: is_correct
  }
}
    `;