'use client';

import { useCallback, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { AnimatePresence, m } from 'framer-motion';
import {
  useInteractions,
  useClick,
  useRole,
  useDismiss,
  useFloating,
  FloatingPortal,
  FloatingOverlay,
  FloatingFocusManager,
} from '@floating-ui/react';
import offset from '../utils/offset';
import fillRef from '../utils/fillRef';

let mousePosition: { x: number; y: number } | undefined;
const getClickPosition = (event: globalThis.MouseEvent) => {
  mousePosition = {
    x: event.pageX,
    y: event.pageY,
  };

  setTimeout(() => {
    mousePosition = undefined;
  }, 100);
};
// save global click
if (typeof window !== 'undefined' && window.document?.documentElement) {
  document.documentElement.removeEventListener('click', getClickPosition, true);
  document.documentElement.addEventListener('click', getClickPosition, true);
}

export interface ModalProps {
  open: boolean;
  onClose?: (open: boolean) => void;
  lockScroll?: boolean;
  focusRef?: React.MutableRefObject<HTMLElement | null>;
  children?: React.ReactNode;
  centered?: boolean;
  className?: string;
  disableBackdrop?: boolean;
  backdropClassName?: string;
}

function getTransformOrigin(pointer: { x: number; y: number }, element: HTMLElement) {
  const elementOffset = offset(element);

  return `${pointer.x - elementOffset.left}px ${pointer.y - elementOffset.top}px`;
}

function Modal({
  open,
  onClose,
  lockScroll = true,
  focusRef,
  children,
  centered,
  className,
  disableBackdrop,
  backdropClassName,
}: ModalProps): JSX.Element | null {
  const { refs, context } = useFloating({
    open,
  });

  const { getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context, {
      enabled: !disableBackdrop,
    }),
  ]);

  const contentRef = useRef<HTMLDivElement>(null);

  const onUpdate = useCallback(() => {
    if (mousePosition && contentRef.current && !contentRef.current.style.transformOrigin) {
      contentRef.current.style.transformOrigin = getTransformOrigin(
        mousePosition,
        contentRef.current,
      );
    }
  }, []);

  const onAnimationComplete = useCallback(() => {
    if (contentRef.current) {
      contentRef.current.style.transformOrigin = '';
    }
  }, []);
  const [animating, setAnimating] = useState(false);
  const onAnimationStart = useCallback(() => {
    setAnimating(true);
  }, []);
  const onExitComplete = useCallback(() => {
    setAnimating(false);
  }, []);

  const ref = useCallback(
    (node: HTMLElement | null) => fillRef(node, refs.setFloating, contentRef),
    [refs.setFloating],
  );

  return open || animating ? (
    <FloatingPortal>
      <AnimatePresence onExitComplete={onExitComplete}>
        {open && (
          <FloatingOverlay lockScroll={lockScroll} className="z-[300] root-popup-base-modal">
            {disableBackdrop ? null : (
              <m.div
                onClick={onClose}
                className={twMerge('absolute inset-0 bg-black bg-opacity-75', backdropClassName)}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.1, ease: 'easeOut' } }}
                exit={{ opacity: 0, transition: { duration: 0.15, ease: 'easeIn' } }}
              />
            )}

            <div
              className={twMerge(
                'h-full flex justify-center overflow-auto',
                centered && 'items-center',
              )}
            >
              <FloatingFocusManager context={context} initialFocus={focusRef} modal={false}>
                <m.div
                  ref={ref}
                  className={twMerge('relative', className)}
                  onUpdate={onUpdate}
                  onAnimationComplete={onAnimationComplete}
                  onAnimationStart={onAnimationStart}
                  variants={{
                    initial: { opacity: 0, scale: 0 },
                    animate: {
                      opacity: 1,
                      scale: 1,
                      transition: { duration: 0.1, ease: 'easeOut' },
                    },
                    exit: {
                      opacity: 0,
                      scale: 0,
                      transition: {
                        duration: 0.15,
                        ease: 'easeIn',
                      },
                    },
                  }}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  {...getFloatingProps()}
                >
                  {children}
                </m.div>
              </FloatingFocusManager>
            </div>
          </FloatingOverlay>
        )}
      </AnimatePresence>
    </FloatingPortal>
  ) : null;
}

export default Modal;
