// Code generated by graphql-codegen. DO NOT EDIT.
import type * as Types from './types';

import { gql } from '@apollo/client';
import { Checkpoint, WritingCheckPoint } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MonthlyUserScoreVariables = Types.Exact<{
  learnerProfileId: Types.Scalars['String']['input'];
  dateTime?: Types.InputMaybe<Types.Scalars['timestamptz']['input']>;
}>;


export type MonthlyUserScore = { monthlyUserScores: Array<{ rank?: string | null, score?: number | null, learnerProfileId?: string | null, totalCompletedTests?: string | null, executionTime?: string | null }> };

export type CheckForDomainVariables = Types.Exact<{
  domain: Types.Scalars['String']['input'];
  requireApproved: Types.Scalars['Boolean']['input'];
}>;


export type CheckForDomain = { checkForDomain: { exist: boolean } };

export type WritingTestLevelsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type WritingTestLevelsQuery = { writingTestLevels: Array<{ id: string, name: string, slug: string, checkPoints: Array<{ checkPoint: { id: string, name: string, nameEn?: string | null } }> }> };

export type WritingCheckPointQueryVariables = Types.Exact<{
  checkPointId: Types.Scalars['uuid']['input'];
}>;


export type WritingCheckPointQuery = { writingCheckPoint?: { testLevelId?: string | null, position: number, checkPoint: { id: string, name: string, nameEn?: string | null }, parts: Array<{ id: string, title?: string | null, position: number, images: Array<{ partId: string, position: number, image: { id: string, label?: string | null, blurHash?: string | null } }> }> } | null };

export type ExecutionTimeQueryVariables = Types.Exact<{
  testLevelId: Types.Scalars['uuid']['input'];
}>;


export type ExecutionTimeQuery = { examSetting?: { executionTime: string } | null };

export type WritingAttemptQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type WritingAttemptQuery = { writingAttempt?: { id: string, executionTime?: string | null, submittedAt?: string | null, checkPointId: string, learnerProfile?: { id: string, displayName: string } | null, partAttempts: Array<{ id: string, answer: string, partId: string, wordCount: number }>, writingCheckPoint?: { testLevelId?: string | null, position: number, checkPoint: { id: string, name: string, nameEn?: string | null }, parts: Array<{ id: string, title?: string | null, position: number, images: Array<{ partId: string, position: number, image: { id: string, label?: string | null, blurHash?: string | null } }> }> } | null } | null };

export type WritingAttemptsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.UserAttemptsBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type WritingAttemptsQuery = { writingAttempts: Array<{ id: string, submittedAt?: string | null, executionTime?: string | null, teacherGrade?: number | null, testLevel: { name: string }, checkPoint: { id: string, name: string, nameEn?: string | null }, learnerProfile?: { id: string, displayName: string } | null, teacher?: { id: string, profile?: { id: string, displayName: string } | null } | null }> };

export type ClassStudentQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type ClassStudentQuery = { student: { id: string, classStudent?: { joinedDate?: string | null, class: { id: string, code: string, name: string, type: Types.TypeClass } } | null } };

export type GetStudentVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetStudent = { student: { id: string, classStudent?: { class: { id: string, code: string, name: string, type: Types.TypeClass } } | null } };

export type GetLastPlacementTestVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.UserAttemptsBoolExp>;
}>;


export type GetLastPlacementTest = { user_attempts: Array<{ id: string }> };

export type GetUserAttemptResultQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type GetUserAttemptResultQuery = { userAttempt?: { id?: string | null, submitted_at?: string | null, grade?: number | null, executionTime?: string | null, rankPercent?: number | null, totalScore?: number | null, totalMaxScore?: number | null, challenge?: { id?: number | null, code?: string | null, name?: string | null } | null, learnerProfile?: { id?: number | null, displayName?: string | null } | null, checkPoint?: { id: string, name: string, name_en?: string | null } | null, testLevel?: { id: string, type: string } | null } | null };

export type GetScoreRankQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetScoreRankQuery = { studentReadingListeningAttempt: { testResult?: { value?: string | null } | null } };

export type GetProvincesVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProvinces = { provinces: Array<{ id: string, name: string }> };

export type GetSchoolsVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.SchoolsBoolExp>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetSchools = { schools: Array<{ id: string, name: string, province?: { id: string } | null }> };

export type GetNotificationVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.NotificationsBoolExp>;
  orderBy?: Types.InputMaybe<Array<Types.NotificationsOrderBy> | Types.NotificationsOrderBy>;
  limit: Types.Scalars['Int']['input'];
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetNotification = { notifications: Array<{ id: string, meta: Record<string, unknown> | Array<unknown>, description?: string | null, description_en?: string | null, title?: string | null, title_en?: string | null, image?: string | null, seen: boolean, type: string, source: string, created_at: string, type_notification: string }> };

export type SendMailVariables = Types.Exact<{
  avatar?: Types.InputMaybe<Types.Scalars['String']['input']>;
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  emails: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type SendMail = { sendEmail: Array<string> };

export type GetPlacementTestVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPlacementTest = { testLevels: Array<{ name: string, checkPoints: Array<{ id: string, name: string, name_en?: string | null, description?: string | null, description_en?: string | null }> }> };

export type GetPhoneVariables = Types.Exact<{
  hash: Types.Scalars['String']['input'];
}>;


export type GetPhone = { check_phone?: { isExist: boolean } | null };

export type GetClassByCodeQueryVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
}>;


export type GetClassByCodeQuery = { classByCode?: { id: string, name: string, code: string, type: Types.TypeClass } | null };

export type TestLevelsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TestLevelsQuery = { testLevels: Array<{ id: string, name: string, description?: string | null, closed: boolean, metadata: Record<string, unknown> | Array<unknown>, slug: string, type: string, descriptionEn?: string | null, playlistId?: string | null }> };

export type ExamTestLevelsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.TestLevelsBoolExp>;
}>;


export type ExamTestLevelsQuery = { testLevels: Array<{ id: string, name: string, description?: string | null, closed: boolean, metadata: Record<string, unknown> | Array<unknown>, slug: string, type: string, descriptionEn?: string | null, playlistId?: string | null, checkPoints: Array<{ id: string, name: string, description?: string | null, isPremium: boolean, nameEn?: string | null, descriptionEn?: string | null, teacher?: { display_name: string } | null }> }> };

export type GetDataPlacementTestVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.TestLevelsBoolExp>;
  checkPointsWhere?: Types.InputMaybe<Types.CheckPointsBoolExp>;
}>;


export type GetDataPlacementTest = { testLevels: Array<{ id: string, check_points: Array<{ id: string, source?: string | null, name: string, name_en?: string | null, description?: string | null, description_en?: string | null }> }> };

export type GetExerciseQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetExerciseQuery = { getDetailExercise: { id: string, image_url?: string | null, is_premium?: boolean | null, level?: Array<string> | null, max_score?: number | null, meta?: Record<string, unknown> | Array<unknown> | null, name?: string | null, name_en?: string | null, public_status?: string | null, teacher_id?: string | null, test_count?: number | null, time?: string | null, type: string, description_en?: string | null, description?: string | null, class_assigns?: Record<string, unknown> | Array<unknown> | null, skills?: Array<string> | null, questions?: Array<{ check_point_id?: string | null, created_at?: string | null, updated_at?: string | null, id: number, multiple?: boolean | null, order?: number | null, question_json?: Record<string, unknown> | Array<unknown> | null, question_text?: string | null, score?: number | null, time?: string | null, title?: string | null, answers?: Array<{ content_json?: Record<string, unknown> | Array<unknown> | null, content_text?: string | null, is_correct?: boolean | null, media?: { type?: string | null, url?: string | null, options?: { end?: string | null, start?: string | null, thumbnail?: string | null } | null } | null }> | null, question_media?: { type?: string | null, url?: string | null, options?: { end?: string | null, start?: string | null, thumbnail?: string | null } | null } | null }> | null } };

export type GetReview2022Variables = Types.Exact<{
  where?: Types.InputMaybe<Types.Review_2022BoolExp>;
}>;


export type GetReview2022 = { review_2022: Array<{ accuracy?: number | null, total_done?: string | null, total_time?: string | null, total_grade?: number | null, conquest?: number | null }> };

export type GetClassUserVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.LearnerProfilesBoolExp>;
}>;


export type GetClassUser = { learner_profiles: Array<{ classes: Array<{ class_id: string }> }> };

export type GetExerciseUserAttemptVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type GetExerciseUserAttempt = { userAttempt?: { id: string, answer_practice?: Record<string, unknown> | Array<unknown> | null, detail_exercise: { id: string, image_url?: string | null, is_premium?: boolean | null, level?: Array<string> | null, max_score?: number | null, meta?: Record<string, unknown> | Array<unknown> | null, name?: string | null, name_en?: string | null, public_status?: string | null, teacher_id?: string | null, test_count?: number | null, time?: string | null, type: string, description_en?: string | null, description?: string | null, class_assigns?: Record<string, unknown> | Array<unknown> | null, skills?: Array<string> | null, questions?: Array<{ check_point_id?: string | null, created_at?: string | null, updated_at?: string | null, id: number, multiple?: boolean | null, order?: number | null, question_json?: Record<string, unknown> | Array<unknown> | null, question_text?: string | null, score?: number | null, time?: string | null, title?: string | null, answers?: Array<{ content_json?: Record<string, unknown> | Array<unknown> | null, content_text?: string | null, is_correct?: boolean | null, media?: { type?: string | null, url?: string | null, options?: { end?: string | null, start?: string | null, thumbnail?: string | null } | null } | null }> | null, question_media?: { type?: string | null, url?: string | null, options?: { end?: string | null, start?: string | null, thumbnail?: string | null } | null } | null }> | null } } | null };

export type GetBufferFromLinkQueryVariables = Types.Exact<{
  url: Types.Scalars['String']['input'];
}>;


export type GetBufferFromLinkQuery = { getBufferFromLink: string };

export type GetAssignTestListVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  page: Types.Scalars['Int']['input'];
  where: Types.WhereAssignTestArgs;
  order: Types.Scalars['JSON']['input'];
}>;


export type GetAssignTestList = { assignTestInprogres: { datas?: Array<{ profile_ids?: Record<string, unknown> | Array<unknown> | null, type?: string | null, teacher_id?: string | null, status?: string | null, id: string, teacher?: { teacher_profile?: { display_name?: string | null } | null } | null, check_point?: { is_premium?: boolean | null, image_url?: string | null, id: string, name?: string | null, type?: string | null, name_en?: string | null, level?: Record<string, unknown> | Array<unknown> | null } | null, test_level?: { name?: string | null } | null }> | null, pagination: { limit: number, page: number, total: number } } };

export type GetAssignTestLoadMoreVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  page: Types.Scalars['Int']['input'];
  where: Types.WhereAssignTestArgs;
  order: Types.Scalars['JSON']['input'];
}>;


export type GetAssignTestLoadMore = { getAssignTest: { datas?: Array<{ profile_ids?: Record<string, unknown> | Array<unknown> | null, type?: string | null, teacher_id?: string | null, status?: string | null, id: string, teacher?: { teacher_profile?: { display_name?: string | null } | null } | null, check_point?: { is_premium?: boolean | null, image_url?: string | null, id: string, name?: string | null, type?: string | null, name_en?: string | null, level?: Record<string, unknown> | Array<unknown> | null } | null, test_level?: { name?: string | null } | null }> | null, pagination: { limit: number, page: number, total: number } } };

export type GetAssignTestDoneVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.UserAttemptsBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetAssignTestDone = { userAttempts: Array<{ id: string, assign_test_id?: string | null, assign_test?: { teacher?: { teacher_profile?: { display_name: string } | null } | null } | null, checkPoint: { id: string, name: string, description?: string | null, description_en?: string | null, image_url?: string | null, is_premium: boolean, name_en?: string | null, type: string } }> };

export type GetPhoneNumberQueryVariables = Types.Exact<{
  hash: Types.Scalars['String']['input'];
}>;


export type GetPhoneNumberQuery = { check_phone?: { isExist: boolean } | null };

export type GetCheckPointQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type GetCheckPointQuery = { examStructures: Array<{ order: number, examType: Types.ExamTypesEnum, displayName: string }>, checkPoint?: { id: string, name: string, type: string, time?: string | null, attempts_allowed: number, nameEn?: string | null, isPremium: boolean, testLevel?: { id: string, name: string, slug: string, examSettings: Array<{ examType: Types.ExamTypesEnum, executionTime: string }> } | null, cpQuestions: Array<{ question: { id: string, name: string, title: string, examType: Types.ExamTypesEnum, questionType: Types.QuestionTypesEnum, maxScore: number, contents: Array<{ id: string, content: Record<string, unknown> | Array<unknown> }>, subquestions: Array<{ id: string, title?: string | null, choices?: Record<string, unknown> | Array<unknown> | null, content?: Record<string, unknown> | Array<unknown> | null, shuffle: boolean, order: number, titlePrefix?: Record<string, unknown> | Array<unknown> | null, detailPrefix?: Record<string, unknown> | Array<unknown> | null, isExample: boolean, details: Array<{ id: string, detail?: Record<string, unknown> | Array<unknown> | null, prefix?: string | null, order: number, example?: { result?: Record<string, unknown> | Array<unknown> | null } | null }> }>, mark?: { total?: string | null } | null } }> } | null };

export type GetCheckPointUserAttemptQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type GetCheckPointUserAttemptQuery = { examStructures: Array<{ order: number, examType: Types.ExamTypesEnum, displayName: string }>, userAttempt?: { id: string, checkPoint: { id: string, name: string, name_en?: string | null, testLevel?: { id: string, name: string, slug: string, examSettings: Array<{ examType: Types.ExamTypesEnum, executionTime: string }> } | null, cpQuestions: Array<{ question: { id: string, name: string, title: string, examType: Types.ExamTypesEnum, questionType: Types.QuestionTypesEnum, maxScore: number, contents: Array<{ id: string, content: Record<string, unknown> | Array<unknown> }>, subquestions: Array<{ id: string, title?: string | null, choices?: Record<string, unknown> | Array<unknown> | null, content?: Record<string, unknown> | Array<unknown> | null, shuffle: boolean, order: number, titlePrefix?: Record<string, unknown> | Array<unknown> | null, detailPrefix?: Record<string, unknown> | Array<unknown> | null, isExample: boolean, details: Array<{ id: string, detail?: Record<string, unknown> | Array<unknown> | null, prefix?: string | null, order: number, result: Record<string, unknown> | Array<unknown>, example?: { result?: Record<string, unknown> | Array<unknown> | null } | null }> }>, mark?: { total?: string | null } | null } }> }, attempts: Array<{ id: string, questionId?: string | null, details: Array<{ id: string, answer?: Record<string, unknown> | Array<unknown> | null, result: Record<string, unknown> | Array<unknown>, questionDetailId: string }> }> } | null };

export type GetUserAttemptResultVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type GetUserAttemptResult = { userAttempt?: { id?: string | null, submitted_at?: string | null, executionTime?: string | null, rankPercent?: number | null, totalScore?: number | null, totalMaxScore?: number | null, challenge?: { id?: number | null, code?: string | null, name?: string | null } | null, learnerProfile?: { id?: number | null, displayName?: string | null } | null, checkPoint?: { id: string } | null, testLevel?: { id: string, type: string } | null } | null };

export type ScoreRankQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type ScoreRankQuery = { studentReadingListeningAttempt: { testResult?: { value?: string | null } | null } };

export type TestLevelChallengeVariables = Types.Exact<{ [key: string]: never; }>;


export type TestLevelChallenge = { testLevels: Array<{ id: string, name: string, slug: string }> };

export type GetCheckPointChallengeVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.CheckPointsBoolExp>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetCheckPointChallenge = { check_points: Array<{ id: string, name: string, name_en?: string | null, type: string, order: number, is_premium: boolean }> };

export type GetUserCanCreateProfileVariables = Types.Exact<{
  userId: Types.Scalars['Int']['input'];
}>;


export type GetUserCanCreateProfile = { userCanCreateProfile: { canCreateProfile: boolean } };

export type GetChallengeByCodeQueryVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
}>;


export type GetChallengeByCodeQuery = { challenges: Array<{ id: string, name: string, code: string, settings?: Record<string, unknown> | Array<unknown> | null, isMixed: boolean, startAt?: string | null, endAt?: string | null, learnerProfile?: { id: string } | null, checkPoint?: { id: string, testLevel?: { id: string, name: string } | null } | null }> };

export type CheckChallengeExtendsVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.ChallengesBoolExp>;
}>;


export type CheckChallengeExtends = { challenges: Array<{ is_limit: boolean, is_hidden_name: boolean, id: string }> };

export type GetLastCheckPointForReportVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.UserAttemptsBoolExp>;
}>;


export type GetLastCheckPointForReport = { user_attempts: Array<{ __typename: 'user_attempts', id: string, check_point: { name: string, name_en?: string | null } }> };

export type GetReview2023Variables = Types.Exact<{
  where?: Types.InputMaybe<Types.Review_2023BoolExp>;
}>;


export type GetReview2023 = { review_2023: Array<{ __typename: 'review_2023', accuracy?: number | null, total_done?: string | null, total_time?: string | null, total_grade?: number | null, conquest?: number | null, score?: number | null }> };

export type GetReview2024Variables = Types.Exact<{
  where?: Types.InputMaybe<Types.Review_2024BoolExp>;
}>;


export type GetReview2024 = { review_2024: Array<{ __typename: 'review_2024', accuracy?: number | null, total_done?: string | null, total_time?: string | null, total_grade?: number | null, conquest?: number | null, score?: number | null }> };


export const MonthlyUserScoreDocument = gql`
    query MonthlyUserScore($learnerProfileId: String!, $dateTime: timestamptz) {
  monthlyUserScores: monthly_user_scores(
    where: {learner_profile_id: {_eq: $learnerProfileId}, date_time: {_eq: $dateTime}}
  ) {
    learnerProfileId: learner_profile_id
    rank
    totalCompletedTests: total_completed_tests
    executionTime: execution_time
    score
  }
}
    `;

/**
 * __useMonthlyUserScore__
 *
 * To run a query within a React component, call `useMonthlyUserScore` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyUserScore` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyUserScore({
 *   variables: {
 *      learnerProfileId: // value for 'learnerProfileId'
 *      dateTime: // value for 'dateTime'
 *   },
 * });
 */
export function useMonthlyUserScore(baseOptions: Apollo.QueryHookOptions<MonthlyUserScore, MonthlyUserScoreVariables> & ({ variables: MonthlyUserScoreVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MonthlyUserScore, MonthlyUserScoreVariables>(MonthlyUserScoreDocument, options);
      }
export function useMonthlyUserScoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonthlyUserScore, MonthlyUserScoreVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MonthlyUserScore, MonthlyUserScoreVariables>(MonthlyUserScoreDocument, options);
        }
export function useMonthlyUserScoreSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MonthlyUserScore, MonthlyUserScoreVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MonthlyUserScore, MonthlyUserScoreVariables>(MonthlyUserScoreDocument, options);
        }
export type MonthlyUserScoreHookResult = ReturnType<typeof useMonthlyUserScore>;
export type MonthlyUserScoreLazyQueryHookResult = ReturnType<typeof useMonthlyUserScoreLazyQuery>;
export type MonthlyUserScoreSuspenseQueryHookResult = ReturnType<typeof useMonthlyUserScoreSuspenseQuery>;
export type MonthlyUserScoreQueryResult = Apollo.QueryResult<MonthlyUserScore, MonthlyUserScoreVariables>;
export const CheckForDomainDocument = gql`
    query CheckForDomain($domain: String!, $requireApproved: Boolean!) {
  checkForDomain(domainName: $domain, requireApproved: $requireApproved) {
    exist
  }
}
    `;

/**
 * __useCheckForDomain__
 *
 * To run a query within a React component, call `useCheckForDomain` and pass it any options that fit your needs.
 * When your component renders, `useCheckForDomain` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckForDomain({
 *   variables: {
 *      domain: // value for 'domain'
 *      requireApproved: // value for 'requireApproved'
 *   },
 * });
 */
export function useCheckForDomain(baseOptions: Apollo.QueryHookOptions<CheckForDomain, CheckForDomainVariables> & ({ variables: CheckForDomainVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckForDomain, CheckForDomainVariables>(CheckForDomainDocument, options);
      }
export function useCheckForDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckForDomain, CheckForDomainVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckForDomain, CheckForDomainVariables>(CheckForDomainDocument, options);
        }
export function useCheckForDomainSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckForDomain, CheckForDomainVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckForDomain, CheckForDomainVariables>(CheckForDomainDocument, options);
        }
export type CheckForDomainHookResult = ReturnType<typeof useCheckForDomain>;
export type CheckForDomainLazyQueryHookResult = ReturnType<typeof useCheckForDomainLazyQuery>;
export type CheckForDomainSuspenseQueryHookResult = ReturnType<typeof useCheckForDomainSuspenseQuery>;
export type CheckForDomainQueryResult = Apollo.QueryResult<CheckForDomain, CheckForDomainVariables>;
export const WritingTestLevelsQueryDocument = gql`
    query WritingTestLevelsQuery {
  writingTestLevels: test_levels(
    where: {writing_check_points: {}}
    order_by: {order: asc}
  ) {
    id
    name
    slug
    checkPoints: writing_check_points(
      order_by: {position: asc}
      where: {checkPoint: {type: {_eq: "writing"}}}
    ) {
      checkPoint {
        ...Checkpoint
      }
    }
  }
}
    ${Checkpoint}`;

/**
 * __useWritingTestLevelsQuery__
 *
 * To run a query within a React component, call `useWritingTestLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWritingTestLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWritingTestLevelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWritingTestLevelsQuery(baseOptions?: Apollo.QueryHookOptions<WritingTestLevelsQuery, WritingTestLevelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WritingTestLevelsQuery, WritingTestLevelsQueryVariables>(WritingTestLevelsQueryDocument, options);
      }
export function useWritingTestLevelsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WritingTestLevelsQuery, WritingTestLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WritingTestLevelsQuery, WritingTestLevelsQueryVariables>(WritingTestLevelsQueryDocument, options);
        }
export function useWritingTestLevelsQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WritingTestLevelsQuery, WritingTestLevelsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WritingTestLevelsQuery, WritingTestLevelsQueryVariables>(WritingTestLevelsQueryDocument, options);
        }
export type WritingTestLevelsQueryHookResult = ReturnType<typeof useWritingTestLevelsQuery>;
export type WritingTestLevelsQueryLazyQueryHookResult = ReturnType<typeof useWritingTestLevelsQueryLazyQuery>;
export type WritingTestLevelsQuerySuspenseQueryHookResult = ReturnType<typeof useWritingTestLevelsQuerySuspenseQuery>;
export type WritingTestLevelsQueryQueryResult = Apollo.QueryResult<WritingTestLevelsQuery, WritingTestLevelsQueryVariables>;
export const WritingCheckPointQueryDocument = gql`
    query WritingCheckPointQuery($checkPointId: uuid!) {
  writingCheckPoint(checkPointId: $checkPointId) {
    ...WritingCheckPoint
  }
}
    ${WritingCheckPoint}`;

/**
 * __useWritingCheckPointQuery__
 *
 * To run a query within a React component, call `useWritingCheckPointQuery` and pass it any options that fit your needs.
 * When your component renders, `useWritingCheckPointQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWritingCheckPointQuery({
 *   variables: {
 *      checkPointId: // value for 'checkPointId'
 *   },
 * });
 */
export function useWritingCheckPointQuery(baseOptions: Apollo.QueryHookOptions<WritingCheckPointQuery, WritingCheckPointQueryVariables> & ({ variables: WritingCheckPointQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WritingCheckPointQuery, WritingCheckPointQueryVariables>(WritingCheckPointQueryDocument, options);
      }
export function useWritingCheckPointQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WritingCheckPointQuery, WritingCheckPointQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WritingCheckPointQuery, WritingCheckPointQueryVariables>(WritingCheckPointQueryDocument, options);
        }
export function useWritingCheckPointQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WritingCheckPointQuery, WritingCheckPointQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WritingCheckPointQuery, WritingCheckPointQueryVariables>(WritingCheckPointQueryDocument, options);
        }
export type WritingCheckPointQueryHookResult = ReturnType<typeof useWritingCheckPointQuery>;
export type WritingCheckPointQueryLazyQueryHookResult = ReturnType<typeof useWritingCheckPointQueryLazyQuery>;
export type WritingCheckPointQuerySuspenseQueryHookResult = ReturnType<typeof useWritingCheckPointQuerySuspenseQuery>;
export type WritingCheckPointQueryQueryResult = Apollo.QueryResult<WritingCheckPointQuery, WritingCheckPointQueryVariables>;
export const ExecutionTimeQueryDocument = gql`
    query ExecutionTimeQuery($testLevelId: uuid!) {
  examSetting: test_level_exam_settings_by_pk(
    exam_type: writing
    test_level_id: $testLevelId
  ) {
    executionTime: execution_time
  }
}
    `;

/**
 * __useExecutionTimeQuery__
 *
 * To run a query within a React component, call `useExecutionTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useExecutionTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExecutionTimeQuery({
 *   variables: {
 *      testLevelId: // value for 'testLevelId'
 *   },
 * });
 */
export function useExecutionTimeQuery(baseOptions: Apollo.QueryHookOptions<ExecutionTimeQuery, ExecutionTimeQueryVariables> & ({ variables: ExecutionTimeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExecutionTimeQuery, ExecutionTimeQueryVariables>(ExecutionTimeQueryDocument, options);
      }
export function useExecutionTimeQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExecutionTimeQuery, ExecutionTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExecutionTimeQuery, ExecutionTimeQueryVariables>(ExecutionTimeQueryDocument, options);
        }
export function useExecutionTimeQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ExecutionTimeQuery, ExecutionTimeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExecutionTimeQuery, ExecutionTimeQueryVariables>(ExecutionTimeQueryDocument, options);
        }
export type ExecutionTimeQueryHookResult = ReturnType<typeof useExecutionTimeQuery>;
export type ExecutionTimeQueryLazyQueryHookResult = ReturnType<typeof useExecutionTimeQueryLazyQuery>;
export type ExecutionTimeQuerySuspenseQueryHookResult = ReturnType<typeof useExecutionTimeQuerySuspenseQuery>;
export type ExecutionTimeQueryQueryResult = Apollo.QueryResult<ExecutionTimeQuery, ExecutionTimeQueryVariables>;
export const WritingAttemptQueryDocument = gql`
    query WritingAttemptQuery($id: uuid!) {
  writingAttempt: user_attempts_by_pk(id: $id) {
    id
    executionTime: execution_time
    submittedAt: submitted_at
    learnerProfile: learner_profile {
      id
      displayName: display_name
    }
    partAttempts: writing_part_attempts {
      id
      answer
      partId
      wordCount
    }
    checkPointId: check_point_id
    writingCheckPoint: writing_check_point {
      ...WritingCheckPoint
    }
  }
}
    ${WritingCheckPoint}`;

/**
 * __useWritingAttemptQuery__
 *
 * To run a query within a React component, call `useWritingAttemptQuery` and pass it any options that fit your needs.
 * When your component renders, `useWritingAttemptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWritingAttemptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWritingAttemptQuery(baseOptions: Apollo.QueryHookOptions<WritingAttemptQuery, WritingAttemptQueryVariables> & ({ variables: WritingAttemptQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WritingAttemptQuery, WritingAttemptQueryVariables>(WritingAttemptQueryDocument, options);
      }
export function useWritingAttemptQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WritingAttemptQuery, WritingAttemptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WritingAttemptQuery, WritingAttemptQueryVariables>(WritingAttemptQueryDocument, options);
        }
export function useWritingAttemptQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WritingAttemptQuery, WritingAttemptQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WritingAttemptQuery, WritingAttemptQueryVariables>(WritingAttemptQueryDocument, options);
        }
export type WritingAttemptQueryHookResult = ReturnType<typeof useWritingAttemptQuery>;
export type WritingAttemptQueryLazyQueryHookResult = ReturnType<typeof useWritingAttemptQueryLazyQuery>;
export type WritingAttemptQuerySuspenseQueryHookResult = ReturnType<typeof useWritingAttemptQuerySuspenseQuery>;
export type WritingAttemptQueryQueryResult = Apollo.QueryResult<WritingAttemptQuery, WritingAttemptQueryVariables>;
export const WritingAttemptsQueryDocument = gql`
    query WritingAttemptsQuery($where: user_attempts_bool_exp, $limit: Int) {
  writingAttempts: user_attempts(
    where: $where
    limit: $limit
    order_by: {submitted_at: desc}
  ) {
    id
    submittedAt: submitted_at
    executionTime: execution_time
    testLevel: test_level {
      name
    }
    checkPoint: check_point {
      ...Checkpoint
    }
    learnerProfile: learner_profile {
      id
      displayName: display_name
    }
    teacher {
      id
      profile: teacher_profile {
        id
        displayName: display_name
      }
    }
    teacherGrade: teacher_grade
  }
}
    ${Checkpoint}`;

/**
 * __useWritingAttemptsQuery__
 *
 * To run a query within a React component, call `useWritingAttemptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWritingAttemptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWritingAttemptsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useWritingAttemptsQuery(baseOptions?: Apollo.QueryHookOptions<WritingAttemptsQuery, WritingAttemptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WritingAttemptsQuery, WritingAttemptsQueryVariables>(WritingAttemptsQueryDocument, options);
      }
export function useWritingAttemptsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WritingAttemptsQuery, WritingAttemptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WritingAttemptsQuery, WritingAttemptsQueryVariables>(WritingAttemptsQueryDocument, options);
        }
export function useWritingAttemptsQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WritingAttemptsQuery, WritingAttemptsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WritingAttemptsQuery, WritingAttemptsQueryVariables>(WritingAttemptsQueryDocument, options);
        }
export type WritingAttemptsQueryHookResult = ReturnType<typeof useWritingAttemptsQuery>;
export type WritingAttemptsQueryLazyQueryHookResult = ReturnType<typeof useWritingAttemptsQueryLazyQuery>;
export type WritingAttemptsQuerySuspenseQueryHookResult = ReturnType<typeof useWritingAttemptsQuerySuspenseQuery>;
export type WritingAttemptsQueryQueryResult = Apollo.QueryResult<WritingAttemptsQuery, WritingAttemptsQueryVariables>;
export const ClassStudentQueryDocument = gql`
    query ClassStudentQuery($id: String!) {
  student(id: $id) {
    id
    classStudent {
      joinedDate
      class {
        id
        code
        name
        type
      }
    }
  }
}
    `;

/**
 * __useClassStudentQuery__
 *
 * To run a query within a React component, call `useClassStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassStudentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassStudentQuery(baseOptions: Apollo.QueryHookOptions<ClassStudentQuery, ClassStudentQueryVariables> & ({ variables: ClassStudentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassStudentQuery, ClassStudentQueryVariables>(ClassStudentQueryDocument, options);
      }
export function useClassStudentQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassStudentQuery, ClassStudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassStudentQuery, ClassStudentQueryVariables>(ClassStudentQueryDocument, options);
        }
export function useClassStudentQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ClassStudentQuery, ClassStudentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClassStudentQuery, ClassStudentQueryVariables>(ClassStudentQueryDocument, options);
        }
export type ClassStudentQueryHookResult = ReturnType<typeof useClassStudentQuery>;
export type ClassStudentQueryLazyQueryHookResult = ReturnType<typeof useClassStudentQueryLazyQuery>;
export type ClassStudentQuerySuspenseQueryHookResult = ReturnType<typeof useClassStudentQuerySuspenseQuery>;
export type ClassStudentQueryQueryResult = Apollo.QueryResult<ClassStudentQuery, ClassStudentQueryVariables>;
export const GetStudentDocument = gql`
    query getStudent($id: String!) {
  student(id: $id) {
    id
    classStudent {
      class {
        id
        code
        name
        type
      }
    }
  }
}
    `;

/**
 * __useGetStudent__
 *
 * To run a query within a React component, call `useGetStudent` and pass it any options that fit your needs.
 * When your component renders, `useGetStudent` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudent({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStudent(baseOptions: Apollo.QueryHookOptions<GetStudent, GetStudentVariables> & ({ variables: GetStudentVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudent, GetStudentVariables>(GetStudentDocument, options);
      }
export function useGetStudentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudent, GetStudentVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudent, GetStudentVariables>(GetStudentDocument, options);
        }
export function useGetStudentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetStudent, GetStudentVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStudent, GetStudentVariables>(GetStudentDocument, options);
        }
export type GetStudentHookResult = ReturnType<typeof useGetStudent>;
export type GetStudentLazyQueryHookResult = ReturnType<typeof useGetStudentLazyQuery>;
export type GetStudentSuspenseQueryHookResult = ReturnType<typeof useGetStudentSuspenseQuery>;
export type GetStudentQueryResult = Apollo.QueryResult<GetStudent, GetStudentVariables>;
export const GetLastPlacementTestDocument = gql`
    query getLastPlacementTest($where: user_attempts_bool_exp) {
  user_attempts(where: $where, order_by: {created_at: desc}, limit: 1) {
    id
  }
}
    `;

/**
 * __useGetLastPlacementTest__
 *
 * To run a query within a React component, call `useGetLastPlacementTest` and pass it any options that fit your needs.
 * When your component renders, `useGetLastPlacementTest` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastPlacementTest({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLastPlacementTest(baseOptions?: Apollo.QueryHookOptions<GetLastPlacementTest, GetLastPlacementTestVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLastPlacementTest, GetLastPlacementTestVariables>(GetLastPlacementTestDocument, options);
      }
export function useGetLastPlacementTestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLastPlacementTest, GetLastPlacementTestVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLastPlacementTest, GetLastPlacementTestVariables>(GetLastPlacementTestDocument, options);
        }
export function useGetLastPlacementTestSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLastPlacementTest, GetLastPlacementTestVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLastPlacementTest, GetLastPlacementTestVariables>(GetLastPlacementTestDocument, options);
        }
export type GetLastPlacementTestHookResult = ReturnType<typeof useGetLastPlacementTest>;
export type GetLastPlacementTestLazyQueryHookResult = ReturnType<typeof useGetLastPlacementTestLazyQuery>;
export type GetLastPlacementTestSuspenseQueryHookResult = ReturnType<typeof useGetLastPlacementTestSuspenseQuery>;
export type GetLastPlacementTestQueryResult = Apollo.QueryResult<GetLastPlacementTest, GetLastPlacementTestVariables>;
export const GetUserAttemptResultQueryDocument = gql`
    query getUserAttemptResultQuery($id: uuid!) {
  userAttempt: get_info_attempt(userAttemptId: $id) {
    id
    executionTime: execution_time
    submitted_at
    rankPercent: rank_percent
    totalScore: total_score
    grade
    totalMaxScore: total_max_score
    challenge {
      id
      code
      name
    }
    learnerProfile {
      id
      displayName: display_name
    }
    checkPoint: check_point {
      id
      name
      name_en
    }
    testLevel: test_level {
      id
      type
    }
  }
}
    `;

/**
 * __useGetUserAttemptResultQuery__
 *
 * To run a query within a React component, call `useGetUserAttemptResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAttemptResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAttemptResultQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserAttemptResultQuery(baseOptions: Apollo.QueryHookOptions<GetUserAttemptResultQuery, GetUserAttemptResultQueryVariables> & ({ variables: GetUserAttemptResultQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAttemptResultQuery, GetUserAttemptResultQueryVariables>(GetUserAttemptResultQueryDocument, options);
      }
export function useGetUserAttemptResultQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAttemptResultQuery, GetUserAttemptResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAttemptResultQuery, GetUserAttemptResultQueryVariables>(GetUserAttemptResultQueryDocument, options);
        }
export function useGetUserAttemptResultQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserAttemptResultQuery, GetUserAttemptResultQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserAttemptResultQuery, GetUserAttemptResultQueryVariables>(GetUserAttemptResultQueryDocument, options);
        }
export type GetUserAttemptResultQueryHookResult = ReturnType<typeof useGetUserAttemptResultQuery>;
export type GetUserAttemptResultQueryLazyQueryHookResult = ReturnType<typeof useGetUserAttemptResultQueryLazyQuery>;
export type GetUserAttemptResultQuerySuspenseQueryHookResult = ReturnType<typeof useGetUserAttemptResultQuerySuspenseQuery>;
export type GetUserAttemptResultQueryQueryResult = Apollo.QueryResult<GetUserAttemptResultQuery, GetUserAttemptResultQueryVariables>;
export const GetScoreRankQueryDocument = gql`
    query GetScoreRankQuery($id: String!) {
  studentReadingListeningAttempt(id: $id) {
    testResult {
      value
    }
  }
}
    `;

/**
 * __useGetScoreRankQuery__
 *
 * To run a query within a React component, call `useGetScoreRankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScoreRankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScoreRankQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScoreRankQuery(baseOptions: Apollo.QueryHookOptions<GetScoreRankQuery, GetScoreRankQueryVariables> & ({ variables: GetScoreRankQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScoreRankQuery, GetScoreRankQueryVariables>(GetScoreRankQueryDocument, options);
      }
export function useGetScoreRankQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScoreRankQuery, GetScoreRankQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScoreRankQuery, GetScoreRankQueryVariables>(GetScoreRankQueryDocument, options);
        }
export function useGetScoreRankQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetScoreRankQuery, GetScoreRankQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetScoreRankQuery, GetScoreRankQueryVariables>(GetScoreRankQueryDocument, options);
        }
export type GetScoreRankQueryHookResult = ReturnType<typeof useGetScoreRankQuery>;
export type GetScoreRankQueryLazyQueryHookResult = ReturnType<typeof useGetScoreRankQueryLazyQuery>;
export type GetScoreRankQuerySuspenseQueryHookResult = ReturnType<typeof useGetScoreRankQuerySuspenseQuery>;
export type GetScoreRankQueryQueryResult = Apollo.QueryResult<GetScoreRankQuery, GetScoreRankQueryVariables>;
export const GetProvincesDocument = gql`
    query GET_PROVINCES {
  provinces {
    id
    name
  }
}
    `;

/**
 * __useGetProvinces__
 *
 * To run a query within a React component, call `useGetProvinces` and pass it any options that fit your needs.
 * When your component renders, `useGetProvinces` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvinces({
 *   variables: {
 *   },
 * });
 */
export function useGetProvinces(baseOptions?: Apollo.QueryHookOptions<GetProvinces, GetProvincesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProvinces, GetProvincesVariables>(GetProvincesDocument, options);
      }
export function useGetProvincesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProvinces, GetProvincesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProvinces, GetProvincesVariables>(GetProvincesDocument, options);
        }
export function useGetProvincesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetProvinces, GetProvincesVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProvinces, GetProvincesVariables>(GetProvincesDocument, options);
        }
export type GetProvincesHookResult = ReturnType<typeof useGetProvinces>;
export type GetProvincesLazyQueryHookResult = ReturnType<typeof useGetProvincesLazyQuery>;
export type GetProvincesSuspenseQueryHookResult = ReturnType<typeof useGetProvincesSuspenseQuery>;
export type GetProvincesQueryResult = Apollo.QueryResult<GetProvinces, GetProvincesVariables>;
export const GetSchoolsDocument = gql`
    query GET_SCHOOLS($where: schools_bool_exp, $offset: Int, $limit: Int) {
  schools(
    where: $where
    limit: $limit
    offset: $offset
    order_by: {created_at: desc, id: desc}
  ) {
    id
    name
    province {
      id
    }
  }
}
    `;

/**
 * __useGetSchools__
 *
 * To run a query within a React component, call `useGetSchools` and pass it any options that fit your needs.
 * When your component renders, `useGetSchools` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchools({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSchools(baseOptions?: Apollo.QueryHookOptions<GetSchools, GetSchoolsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchools, GetSchoolsVariables>(GetSchoolsDocument, options);
      }
export function useGetSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchools, GetSchoolsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchools, GetSchoolsVariables>(GetSchoolsDocument, options);
        }
export function useGetSchoolsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSchools, GetSchoolsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSchools, GetSchoolsVariables>(GetSchoolsDocument, options);
        }
export type GetSchoolsHookResult = ReturnType<typeof useGetSchools>;
export type GetSchoolsLazyQueryHookResult = ReturnType<typeof useGetSchoolsLazyQuery>;
export type GetSchoolsSuspenseQueryHookResult = ReturnType<typeof useGetSchoolsSuspenseQuery>;
export type GetSchoolsQueryResult = Apollo.QueryResult<GetSchools, GetSchoolsVariables>;
export const GetNotificationDocument = gql`
    query getNotification($where: notifications_bool_exp, $orderBy: [notifications_order_by!], $limit: Int!, $offset: Int) {
  notifications(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
    id
    meta
    description
    description_en
    title
    title_en
    image
    seen
    type
    source
    created_at
    type_notification
  }
}
    `;

/**
 * __useGetNotification__
 *
 * To run a query within a React component, call `useGetNotification` and pass it any options that fit your needs.
 * When your component renders, `useGetNotification` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotification({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetNotification(baseOptions: Apollo.QueryHookOptions<GetNotification, GetNotificationVariables> & ({ variables: GetNotificationVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotification, GetNotificationVariables>(GetNotificationDocument, options);
      }
export function useGetNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotification, GetNotificationVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotification, GetNotificationVariables>(GetNotificationDocument, options);
        }
export function useGetNotificationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetNotification, GetNotificationVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNotification, GetNotificationVariables>(GetNotificationDocument, options);
        }
export type GetNotificationHookResult = ReturnType<typeof useGetNotification>;
export type GetNotificationLazyQueryHookResult = ReturnType<typeof useGetNotificationLazyQuery>;
export type GetNotificationSuspenseQueryHookResult = ReturnType<typeof useGetNotificationSuspenseQuery>;
export type GetNotificationQueryResult = Apollo.QueryResult<GetNotification, GetNotificationVariables>;
export const SendMailDocument = gql`
    query SendMail($avatar: String, $name: String, $emails: [String!]!) {
  sendEmail(avatar: $avatar, name: $name, emails: $emails)
}
    `;

/**
 * __useSendMail__
 *
 * To run a query within a React component, call `useSendMail` and pass it any options that fit your needs.
 * When your component renders, `useSendMail` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendMail({
 *   variables: {
 *      avatar: // value for 'avatar'
 *      name: // value for 'name'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useSendMail(baseOptions: Apollo.QueryHookOptions<SendMail, SendMailVariables> & ({ variables: SendMailVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SendMail, SendMailVariables>(SendMailDocument, options);
      }
export function useSendMailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SendMail, SendMailVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SendMail, SendMailVariables>(SendMailDocument, options);
        }
export function useSendMailSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SendMail, SendMailVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SendMail, SendMailVariables>(SendMailDocument, options);
        }
export type SendMailHookResult = ReturnType<typeof useSendMail>;
export type SendMailLazyQueryHookResult = ReturnType<typeof useSendMailLazyQuery>;
export type SendMailSuspenseQueryHookResult = ReturnType<typeof useSendMailSuspenseQuery>;
export type SendMailQueryResult = Apollo.QueryResult<SendMail, SendMailVariables>;
export const GetPlacementTestDocument = gql`
    query getPlacementTest {
  testLevels: test_levels(where: {type: {_eq: "PLACEMENT_TEST"}}) {
    name
    checkPoints: check_points(order_by: {order: asc}) {
      id
      name
      name_en
      description
      description_en
    }
  }
}
    `;

/**
 * __useGetPlacementTest__
 *
 * To run a query within a React component, call `useGetPlacementTest` and pass it any options that fit your needs.
 * When your component renders, `useGetPlacementTest` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlacementTest({
 *   variables: {
 *   },
 * });
 */
export function useGetPlacementTest(baseOptions?: Apollo.QueryHookOptions<GetPlacementTest, GetPlacementTestVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlacementTest, GetPlacementTestVariables>(GetPlacementTestDocument, options);
      }
export function useGetPlacementTestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlacementTest, GetPlacementTestVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlacementTest, GetPlacementTestVariables>(GetPlacementTestDocument, options);
        }
export function useGetPlacementTestSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPlacementTest, GetPlacementTestVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPlacementTest, GetPlacementTestVariables>(GetPlacementTestDocument, options);
        }
export type GetPlacementTestHookResult = ReturnType<typeof useGetPlacementTest>;
export type GetPlacementTestLazyQueryHookResult = ReturnType<typeof useGetPlacementTestLazyQuery>;
export type GetPlacementTestSuspenseQueryHookResult = ReturnType<typeof useGetPlacementTestSuspenseQuery>;
export type GetPlacementTestQueryResult = Apollo.QueryResult<GetPlacementTest, GetPlacementTestVariables>;
export const GetPhoneDocument = gql`
    query GetPhone($hash: String!) {
  check_phone(hash: $hash) {
    isExist
  }
}
    `;

/**
 * __useGetPhone__
 *
 * To run a query within a React component, call `useGetPhone` and pass it any options that fit your needs.
 * When your component renders, `useGetPhone` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhone({
 *   variables: {
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useGetPhone(baseOptions: Apollo.QueryHookOptions<GetPhone, GetPhoneVariables> & ({ variables: GetPhoneVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPhone, GetPhoneVariables>(GetPhoneDocument, options);
      }
export function useGetPhoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhone, GetPhoneVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPhone, GetPhoneVariables>(GetPhoneDocument, options);
        }
export function useGetPhoneSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPhone, GetPhoneVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPhone, GetPhoneVariables>(GetPhoneDocument, options);
        }
export type GetPhoneHookResult = ReturnType<typeof useGetPhone>;
export type GetPhoneLazyQueryHookResult = ReturnType<typeof useGetPhoneLazyQuery>;
export type GetPhoneSuspenseQueryHookResult = ReturnType<typeof useGetPhoneSuspenseQuery>;
export type GetPhoneQueryResult = Apollo.QueryResult<GetPhone, GetPhoneVariables>;
export const GetClassByCodeQueryDocument = gql`
    query GetClassByCodeQuery($code: String!) {
  classByCode(code: $code) {
    id
    name
    code
    type
  }
}
    `;

/**
 * __useGetClassByCodeQuery__
 *
 * To run a query within a React component, call `useGetClassByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetClassByCodeQuery(baseOptions: Apollo.QueryHookOptions<GetClassByCodeQuery, GetClassByCodeQueryVariables> & ({ variables: GetClassByCodeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassByCodeQuery, GetClassByCodeQueryVariables>(GetClassByCodeQueryDocument, options);
      }
export function useGetClassByCodeQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassByCodeQuery, GetClassByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassByCodeQuery, GetClassByCodeQueryVariables>(GetClassByCodeQueryDocument, options);
        }
export function useGetClassByCodeQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetClassByCodeQuery, GetClassByCodeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClassByCodeQuery, GetClassByCodeQueryVariables>(GetClassByCodeQueryDocument, options);
        }
export type GetClassByCodeQueryHookResult = ReturnType<typeof useGetClassByCodeQuery>;
export type GetClassByCodeQueryLazyQueryHookResult = ReturnType<typeof useGetClassByCodeQueryLazyQuery>;
export type GetClassByCodeQuerySuspenseQueryHookResult = ReturnType<typeof useGetClassByCodeQuerySuspenseQuery>;
export type GetClassByCodeQueryQueryResult = Apollo.QueryResult<GetClassByCodeQuery, GetClassByCodeQueryVariables>;
export const TestLevelsQueryDocument = gql`
    query TestLevelsQuery {
  testLevels: test_levels(order_by: {order: asc}) {
    id
    name
    description
    descriptionEn: description_en
    playlistId: playlist_id
    closed
    metadata
    slug
    type
  }
}
    `;

/**
 * __useTestLevelsQuery__
 *
 * To run a query within a React component, call `useTestLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestLevelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestLevelsQuery(baseOptions?: Apollo.QueryHookOptions<TestLevelsQuery, TestLevelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestLevelsQuery, TestLevelsQueryVariables>(TestLevelsQueryDocument, options);
      }
export function useTestLevelsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestLevelsQuery, TestLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestLevelsQuery, TestLevelsQueryVariables>(TestLevelsQueryDocument, options);
        }
export function useTestLevelsQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TestLevelsQuery, TestLevelsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TestLevelsQuery, TestLevelsQueryVariables>(TestLevelsQueryDocument, options);
        }
export type TestLevelsQueryHookResult = ReturnType<typeof useTestLevelsQuery>;
export type TestLevelsQueryLazyQueryHookResult = ReturnType<typeof useTestLevelsQueryLazyQuery>;
export type TestLevelsQuerySuspenseQueryHookResult = ReturnType<typeof useTestLevelsQuerySuspenseQuery>;
export type TestLevelsQueryQueryResult = Apollo.QueryResult<TestLevelsQuery, TestLevelsQueryVariables>;
export const ExamTestLevelsQueryDocument = gql`
    query ExamTestLevelsQuery($where: test_levels_bool_exp) {
  testLevels: test_levels(where: $where, order_by: {order: asc}) {
    id
    name
    description
    descriptionEn: description_en
    playlistId: playlist_id
    closed
    metadata
    slug
    type
    checkPoints: check_points(
      order_by: {order: asc}
      where: {type: {_eq: "exam"}, attempts_allowed: {_eq: -1}}
    ) {
      id
      name
      teacher {
        display_name
      }
      isPremium: is_premium
      nameEn: name_en
      description
      descriptionEn: description_en
    }
  }
}
    `;

/**
 * __useExamTestLevelsQuery__
 *
 * To run a query within a React component, call `useExamTestLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamTestLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamTestLevelsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useExamTestLevelsQuery(baseOptions?: Apollo.QueryHookOptions<ExamTestLevelsQuery, ExamTestLevelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExamTestLevelsQuery, ExamTestLevelsQueryVariables>(ExamTestLevelsQueryDocument, options);
      }
export function useExamTestLevelsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExamTestLevelsQuery, ExamTestLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExamTestLevelsQuery, ExamTestLevelsQueryVariables>(ExamTestLevelsQueryDocument, options);
        }
export function useExamTestLevelsQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ExamTestLevelsQuery, ExamTestLevelsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExamTestLevelsQuery, ExamTestLevelsQueryVariables>(ExamTestLevelsQueryDocument, options);
        }
export type ExamTestLevelsQueryHookResult = ReturnType<typeof useExamTestLevelsQuery>;
export type ExamTestLevelsQueryLazyQueryHookResult = ReturnType<typeof useExamTestLevelsQueryLazyQuery>;
export type ExamTestLevelsQuerySuspenseQueryHookResult = ReturnType<typeof useExamTestLevelsQuerySuspenseQuery>;
export type ExamTestLevelsQueryQueryResult = Apollo.QueryResult<ExamTestLevelsQuery, ExamTestLevelsQueryVariables>;
export const GetDataPlacementTestDocument = gql`
    query getDataPlacementTest($where: test_levels_bool_exp, $checkPointsWhere: check_points_bool_exp) {
  testLevels: test_levels(where: $where) {
    id
    check_points(where: $checkPointsWhere, order_by: {order: asc}) {
      id
      source
      name
      name_en
      description
      description_en
    }
  }
}
    `;

/**
 * __useGetDataPlacementTest__
 *
 * To run a query within a React component, call `useGetDataPlacementTest` and pass it any options that fit your needs.
 * When your component renders, `useGetDataPlacementTest` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataPlacementTest({
 *   variables: {
 *      where: // value for 'where'
 *      checkPointsWhere: // value for 'checkPointsWhere'
 *   },
 * });
 */
export function useGetDataPlacementTest(baseOptions?: Apollo.QueryHookOptions<GetDataPlacementTest, GetDataPlacementTestVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataPlacementTest, GetDataPlacementTestVariables>(GetDataPlacementTestDocument, options);
      }
export function useGetDataPlacementTestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataPlacementTest, GetDataPlacementTestVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataPlacementTest, GetDataPlacementTestVariables>(GetDataPlacementTestDocument, options);
        }
export function useGetDataPlacementTestSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDataPlacementTest, GetDataPlacementTestVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDataPlacementTest, GetDataPlacementTestVariables>(GetDataPlacementTestDocument, options);
        }
export type GetDataPlacementTestHookResult = ReturnType<typeof useGetDataPlacementTest>;
export type GetDataPlacementTestLazyQueryHookResult = ReturnType<typeof useGetDataPlacementTestLazyQuery>;
export type GetDataPlacementTestSuspenseQueryHookResult = ReturnType<typeof useGetDataPlacementTestSuspenseQuery>;
export type GetDataPlacementTestQueryResult = Apollo.QueryResult<GetDataPlacementTest, GetDataPlacementTestVariables>;
export const GetExerciseQueryDocument = gql`
    query GetExerciseQuery($id: String!) {
  getDetailExercise(id: $id) {
    id
    image_url
    is_premium
    level
    max_score
    meta
    name
    name_en
    public_status
    teacher_id
    test_count
    time
    type
    description_en
    description
    class_assigns
    skills
    questions {
      check_point_id
      created_at
      updated_at
      id
      multiple
      order
      question_json
      question_text
      score
      time
      title
      answers {
        content_json
        content_text
        is_correct
        media {
          type
          url
          options {
            end
            start
            thumbnail
          }
        }
      }
      question_media {
        type
        url
        options {
          end
          start
          thumbnail
        }
      }
    }
  }
}
    `;

/**
 * __useGetExerciseQuery__
 *
 * To run a query within a React component, call `useGetExerciseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExerciseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExerciseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExerciseQuery(baseOptions: Apollo.QueryHookOptions<GetExerciseQuery, GetExerciseQueryVariables> & ({ variables: GetExerciseQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExerciseQuery, GetExerciseQueryVariables>(GetExerciseQueryDocument, options);
      }
export function useGetExerciseQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExerciseQuery, GetExerciseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExerciseQuery, GetExerciseQueryVariables>(GetExerciseQueryDocument, options);
        }
export function useGetExerciseQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetExerciseQuery, GetExerciseQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetExerciseQuery, GetExerciseQueryVariables>(GetExerciseQueryDocument, options);
        }
export type GetExerciseQueryHookResult = ReturnType<typeof useGetExerciseQuery>;
export type GetExerciseQueryLazyQueryHookResult = ReturnType<typeof useGetExerciseQueryLazyQuery>;
export type GetExerciseQuerySuspenseQueryHookResult = ReturnType<typeof useGetExerciseQuerySuspenseQuery>;
export type GetExerciseQueryQueryResult = Apollo.QueryResult<GetExerciseQuery, GetExerciseQueryVariables>;
export const GetReview2022Document = gql`
    query getReview2022($where: review_2022_bool_exp) {
  review_2022(where: $where) {
    accuracy
    total_done
    total_time
    total_grade
    conquest
  }
}
    `;

/**
 * __useGetReview2022__
 *
 * To run a query within a React component, call `useGetReview2022` and pass it any options that fit your needs.
 * When your component renders, `useGetReview2022` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReview2022({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetReview2022(baseOptions?: Apollo.QueryHookOptions<GetReview2022, GetReview2022Variables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReview2022, GetReview2022Variables>(GetReview2022Document, options);
      }
export function useGetReview2022LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReview2022, GetReview2022Variables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReview2022, GetReview2022Variables>(GetReview2022Document, options);
        }
export function useGetReview2022SuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetReview2022, GetReview2022Variables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReview2022, GetReview2022Variables>(GetReview2022Document, options);
        }
export type GetReview2022HookResult = ReturnType<typeof useGetReview2022>;
export type GetReview2022LazyQueryHookResult = ReturnType<typeof useGetReview2022LazyQuery>;
export type GetReview2022SuspenseQueryHookResult = ReturnType<typeof useGetReview2022SuspenseQuery>;
export type GetReview2022QueryResult = Apollo.QueryResult<GetReview2022, GetReview2022Variables>;
export const GetClassUserDocument = gql`
    query getClassUser($where: learner_profiles_bool_exp) {
  learner_profiles(where: $where) {
    classes {
      class_id
    }
  }
}
    `;

/**
 * __useGetClassUser__
 *
 * To run a query within a React component, call `useGetClassUser` and pass it any options that fit your needs.
 * When your component renders, `useGetClassUser` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassUser({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClassUser(baseOptions?: Apollo.QueryHookOptions<GetClassUser, GetClassUserVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassUser, GetClassUserVariables>(GetClassUserDocument, options);
      }
export function useGetClassUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassUser, GetClassUserVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassUser, GetClassUserVariables>(GetClassUserDocument, options);
        }
export function useGetClassUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetClassUser, GetClassUserVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClassUser, GetClassUserVariables>(GetClassUserDocument, options);
        }
export type GetClassUserHookResult = ReturnType<typeof useGetClassUser>;
export type GetClassUserLazyQueryHookResult = ReturnType<typeof useGetClassUserLazyQuery>;
export type GetClassUserSuspenseQueryHookResult = ReturnType<typeof useGetClassUserSuspenseQuery>;
export type GetClassUserQueryResult = Apollo.QueryResult<GetClassUser, GetClassUserVariables>;
export const GetExerciseUserAttemptDocument = gql`
    query GetExerciseUserAttempt($id: uuid!) {
  userAttempt: user_attempts_by_pk(id: $id) {
    id
    detail_exercise {
      id
      image_url
      is_premium
      level
      max_score
      meta
      name
      name_en
      public_status
      teacher_id
      test_count
      time
      type
      description_en
      description
      class_assigns
      skills
      questions {
        check_point_id
        created_at
        updated_at
        id
        multiple
        order
        question_json
        question_text
        score
        time
        title
        answers {
          content_json
          content_text
          is_correct
          media {
            type
            url
            options {
              end
              start
              thumbnail
            }
          }
        }
        question_media {
          type
          url
          options {
            end
            start
            thumbnail
          }
        }
      }
    }
    answer_practice
  }
}
    `;

/**
 * __useGetExerciseUserAttempt__
 *
 * To run a query within a React component, call `useGetExerciseUserAttempt` and pass it any options that fit your needs.
 * When your component renders, `useGetExerciseUserAttempt` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExerciseUserAttempt({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExerciseUserAttempt(baseOptions: Apollo.QueryHookOptions<GetExerciseUserAttempt, GetExerciseUserAttemptVariables> & ({ variables: GetExerciseUserAttemptVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExerciseUserAttempt, GetExerciseUserAttemptVariables>(GetExerciseUserAttemptDocument, options);
      }
export function useGetExerciseUserAttemptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExerciseUserAttempt, GetExerciseUserAttemptVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExerciseUserAttempt, GetExerciseUserAttemptVariables>(GetExerciseUserAttemptDocument, options);
        }
export function useGetExerciseUserAttemptSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetExerciseUserAttempt, GetExerciseUserAttemptVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetExerciseUserAttempt, GetExerciseUserAttemptVariables>(GetExerciseUserAttemptDocument, options);
        }
export type GetExerciseUserAttemptHookResult = ReturnType<typeof useGetExerciseUserAttempt>;
export type GetExerciseUserAttemptLazyQueryHookResult = ReturnType<typeof useGetExerciseUserAttemptLazyQuery>;
export type GetExerciseUserAttemptSuspenseQueryHookResult = ReturnType<typeof useGetExerciseUserAttemptSuspenseQuery>;
export type GetExerciseUserAttemptQueryResult = Apollo.QueryResult<GetExerciseUserAttempt, GetExerciseUserAttemptVariables>;
export const GetBufferFromLinkQueryDocument = gql`
    query GetBufferFromLinkQuery($url: String!) {
  getBufferFromLink(url: $url)
}
    `;

/**
 * __useGetBufferFromLinkQuery__
 *
 * To run a query within a React component, call `useGetBufferFromLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBufferFromLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBufferFromLinkQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGetBufferFromLinkQuery(baseOptions: Apollo.QueryHookOptions<GetBufferFromLinkQuery, GetBufferFromLinkQueryVariables> & ({ variables: GetBufferFromLinkQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBufferFromLinkQuery, GetBufferFromLinkQueryVariables>(GetBufferFromLinkQueryDocument, options);
      }
export function useGetBufferFromLinkQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBufferFromLinkQuery, GetBufferFromLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBufferFromLinkQuery, GetBufferFromLinkQueryVariables>(GetBufferFromLinkQueryDocument, options);
        }
export function useGetBufferFromLinkQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBufferFromLinkQuery, GetBufferFromLinkQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBufferFromLinkQuery, GetBufferFromLinkQueryVariables>(GetBufferFromLinkQueryDocument, options);
        }
export type GetBufferFromLinkQueryHookResult = ReturnType<typeof useGetBufferFromLinkQuery>;
export type GetBufferFromLinkQueryLazyQueryHookResult = ReturnType<typeof useGetBufferFromLinkQueryLazyQuery>;
export type GetBufferFromLinkQuerySuspenseQueryHookResult = ReturnType<typeof useGetBufferFromLinkQuerySuspenseQuery>;
export type GetBufferFromLinkQueryQueryResult = Apollo.QueryResult<GetBufferFromLinkQuery, GetBufferFromLinkQueryVariables>;
export const GetAssignTestListDocument = gql`
    query getAssignTestList($limit: Int!, $page: Int!, $where: WhereAssignTestArgs!, $order: JSON!) {
  assignTestInprogres: getAssignTest(
    limit: $limit
    page: $page
    where: $where
    order: $order
  ) {
    datas {
      profile_ids
      teacher {
        teacher_profile {
          display_name
        }
      }
      check_point {
        is_premium
        image_url
        id
        name
        type
        name_en
        level
      }
      test_level {
        name
      }
      type
      teacher_id
      status
      id
    }
    pagination {
      limit
      page
      total
    }
  }
}
    `;

/**
 * __useGetAssignTestList__
 *
 * To run a query within a React component, call `useGetAssignTestList` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignTestList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignTestList({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAssignTestList(baseOptions: Apollo.QueryHookOptions<GetAssignTestList, GetAssignTestListVariables> & ({ variables: GetAssignTestListVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignTestList, GetAssignTestListVariables>(GetAssignTestListDocument, options);
      }
export function useGetAssignTestListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignTestList, GetAssignTestListVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignTestList, GetAssignTestListVariables>(GetAssignTestListDocument, options);
        }
export function useGetAssignTestListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAssignTestList, GetAssignTestListVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssignTestList, GetAssignTestListVariables>(GetAssignTestListDocument, options);
        }
export type GetAssignTestListHookResult = ReturnType<typeof useGetAssignTestList>;
export type GetAssignTestListLazyQueryHookResult = ReturnType<typeof useGetAssignTestListLazyQuery>;
export type GetAssignTestListSuspenseQueryHookResult = ReturnType<typeof useGetAssignTestListSuspenseQuery>;
export type GetAssignTestListQueryResult = Apollo.QueryResult<GetAssignTestList, GetAssignTestListVariables>;
export const GetAssignTestLoadMoreDocument = gql`
    query getAssignTestLoadMore($limit: Int!, $page: Int!, $where: WhereAssignTestArgs!, $order: JSON!) {
  getAssignTest(limit: $limit, page: $page, where: $where, order: $order) {
    datas {
      profile_ids
      teacher {
        teacher_profile {
          display_name
        }
      }
      check_point {
        is_premium
        image_url
        id
        name
        type
        name_en
        level
      }
      test_level {
        name
      }
      type
      teacher_id
      status
      id
    }
    pagination {
      limit
      page
      total
    }
  }
}
    `;

/**
 * __useGetAssignTestLoadMore__
 *
 * To run a query within a React component, call `useGetAssignTestLoadMore` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignTestLoadMore` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignTestLoadMore({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAssignTestLoadMore(baseOptions: Apollo.QueryHookOptions<GetAssignTestLoadMore, GetAssignTestLoadMoreVariables> & ({ variables: GetAssignTestLoadMoreVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignTestLoadMore, GetAssignTestLoadMoreVariables>(GetAssignTestLoadMoreDocument, options);
      }
export function useGetAssignTestLoadMoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignTestLoadMore, GetAssignTestLoadMoreVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignTestLoadMore, GetAssignTestLoadMoreVariables>(GetAssignTestLoadMoreDocument, options);
        }
export function useGetAssignTestLoadMoreSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAssignTestLoadMore, GetAssignTestLoadMoreVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssignTestLoadMore, GetAssignTestLoadMoreVariables>(GetAssignTestLoadMoreDocument, options);
        }
export type GetAssignTestLoadMoreHookResult = ReturnType<typeof useGetAssignTestLoadMore>;
export type GetAssignTestLoadMoreLazyQueryHookResult = ReturnType<typeof useGetAssignTestLoadMoreLazyQuery>;
export type GetAssignTestLoadMoreSuspenseQueryHookResult = ReturnType<typeof useGetAssignTestLoadMoreSuspenseQuery>;
export type GetAssignTestLoadMoreQueryResult = Apollo.QueryResult<GetAssignTestLoadMore, GetAssignTestLoadMoreVariables>;
export const GetAssignTestDoneDocument = gql`
    query getAssignTestDone($where: user_attempts_bool_exp, $limit: Int, $offset: Int) {
  userAttempts: user_attempts(
    where: $where
    order_by: {submitted_at: desc}
    limit: $limit
    offset: $offset
  ) {
    id
    assign_test_id
    assign_test {
      teacher {
        teacher_profile {
          display_name
        }
      }
    }
    checkPoint: check_point {
      id
      name
      description
      description_en
      image_url
      is_premium
      name_en
      type
    }
  }
}
    `;

/**
 * __useGetAssignTestDone__
 *
 * To run a query within a React component, call `useGetAssignTestDone` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignTestDone` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignTestDone({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAssignTestDone(baseOptions?: Apollo.QueryHookOptions<GetAssignTestDone, GetAssignTestDoneVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignTestDone, GetAssignTestDoneVariables>(GetAssignTestDoneDocument, options);
      }
export function useGetAssignTestDoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignTestDone, GetAssignTestDoneVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignTestDone, GetAssignTestDoneVariables>(GetAssignTestDoneDocument, options);
        }
export function useGetAssignTestDoneSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAssignTestDone, GetAssignTestDoneVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssignTestDone, GetAssignTestDoneVariables>(GetAssignTestDoneDocument, options);
        }
export type GetAssignTestDoneHookResult = ReturnType<typeof useGetAssignTestDone>;
export type GetAssignTestDoneLazyQueryHookResult = ReturnType<typeof useGetAssignTestDoneLazyQuery>;
export type GetAssignTestDoneSuspenseQueryHookResult = ReturnType<typeof useGetAssignTestDoneSuspenseQuery>;
export type GetAssignTestDoneQueryResult = Apollo.QueryResult<GetAssignTestDone, GetAssignTestDoneVariables>;
export const GetPhoneNumberQueryDocument = gql`
    query GetPhoneNumberQuery($hash: String!) {
  check_phone(hash: $hash) {
    isExist
  }
}
    `;

/**
 * __useGetPhoneNumberQuery__
 *
 * To run a query within a React component, call `useGetPhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhoneNumberQuery({
 *   variables: {
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useGetPhoneNumberQuery(baseOptions: Apollo.QueryHookOptions<GetPhoneNumberQuery, GetPhoneNumberQueryVariables> & ({ variables: GetPhoneNumberQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPhoneNumberQuery, GetPhoneNumberQueryVariables>(GetPhoneNumberQueryDocument, options);
      }
export function useGetPhoneNumberQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhoneNumberQuery, GetPhoneNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPhoneNumberQuery, GetPhoneNumberQueryVariables>(GetPhoneNumberQueryDocument, options);
        }
export function useGetPhoneNumberQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPhoneNumberQuery, GetPhoneNumberQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPhoneNumberQuery, GetPhoneNumberQueryVariables>(GetPhoneNumberQueryDocument, options);
        }
export type GetPhoneNumberQueryHookResult = ReturnType<typeof useGetPhoneNumberQuery>;
export type GetPhoneNumberQueryLazyQueryHookResult = ReturnType<typeof useGetPhoneNumberQueryLazyQuery>;
export type GetPhoneNumberQuerySuspenseQueryHookResult = ReturnType<typeof useGetPhoneNumberQuerySuspenseQuery>;
export type GetPhoneNumberQueryQueryResult = Apollo.QueryResult<GetPhoneNumberQuery, GetPhoneNumberQueryVariables>;
export const GetCheckPointQueryDocument = gql`
    query GetCheckPointQuery($id: uuid!) {
  examStructures: exam_structures(order_by: {order: asc}) {
    examType: exam_type
    displayName: display_name
    order
  }
  checkPoint: check_points_by_pk(id: $id) {
    id
    name
    type
    time
    nameEn: name_en
    attempts_allowed
    isPremium: is_premium
    testLevel: test_level {
      id
      name
      slug
      examSettings: exam_settings {
        examType: exam_type
        executionTime: execution_time
      }
    }
    cpQuestions: cp_questions(order_by: {order: asc}) {
      question {
        id
        name
        title
        examType: exam_type
        questionType: question_type
        contents {
          id
          content
        }
        subquestions(order_by: {order: asc}) {
          id
          title
          titlePrefix: title_prefix
          detailPrefix: detail_prefix
          choices
          content
          shuffle
          isExample: is_example
          order
          details(order_by: {order: asc}) {
            id
            detail
            example {
              result
            }
            prefix
            order
          }
        }
        mark {
          total
        }
        maxScore: max_score
      }
    }
  }
}
    `;

/**
 * __useGetCheckPointQuery__
 *
 * To run a query within a React component, call `useGetCheckPointQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckPointQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckPointQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCheckPointQuery(baseOptions: Apollo.QueryHookOptions<GetCheckPointQuery, GetCheckPointQueryVariables> & ({ variables: GetCheckPointQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCheckPointQuery, GetCheckPointQueryVariables>(GetCheckPointQueryDocument, options);
      }
export function useGetCheckPointQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckPointQuery, GetCheckPointQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCheckPointQuery, GetCheckPointQueryVariables>(GetCheckPointQueryDocument, options);
        }
export function useGetCheckPointQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCheckPointQuery, GetCheckPointQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCheckPointQuery, GetCheckPointQueryVariables>(GetCheckPointQueryDocument, options);
        }
export type GetCheckPointQueryHookResult = ReturnType<typeof useGetCheckPointQuery>;
export type GetCheckPointQueryLazyQueryHookResult = ReturnType<typeof useGetCheckPointQueryLazyQuery>;
export type GetCheckPointQuerySuspenseQueryHookResult = ReturnType<typeof useGetCheckPointQuerySuspenseQuery>;
export type GetCheckPointQueryQueryResult = Apollo.QueryResult<GetCheckPointQuery, GetCheckPointQueryVariables>;
export const GetCheckPointUserAttemptQueryDocument = gql`
    query GetCheckPointUserAttemptQuery($id: uuid!) {
  examStructures: exam_structures(order_by: {order: asc}) {
    examType: exam_type
    displayName: display_name
    order
  }
  userAttempt: user_attempts_by_pk(id: $id) {
    id
    checkPoint: check_point {
      id
      name
      name_en
      testLevel: test_level {
        id
        name
        slug
        examSettings: exam_settings {
          examType: exam_type
          executionTime: execution_time
        }
      }
      cpQuestions: cp_questions(order_by: {order: asc}) {
        question {
          id
          name
          title
          examType: exam_type
          questionType: question_type
          contents {
            id
            content
          }
          subquestions(order_by: {order: asc}) {
            id
            title
            titlePrefix: title_prefix
            detailPrefix: detail_prefix
            choices
            content
            shuffle
            isExample: is_example
            order
            details(order_by: {order: asc}) {
              id
              detail
              prefix
              order
              example {
                result
              }
              result
            }
          }
          mark {
            total
          }
          maxScore: max_score
        }
      }
    }
    attempts: question_attempts(order_by: {order: asc}) {
      id
      questionId: question_id
      details {
        id
        questionDetailId: question_detail_id
        answer
        result
      }
    }
  }
}
    `;

/**
 * __useGetCheckPointUserAttemptQuery__
 *
 * To run a query within a React component, call `useGetCheckPointUserAttemptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckPointUserAttemptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckPointUserAttemptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCheckPointUserAttemptQuery(baseOptions: Apollo.QueryHookOptions<GetCheckPointUserAttemptQuery, GetCheckPointUserAttemptQueryVariables> & ({ variables: GetCheckPointUserAttemptQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCheckPointUserAttemptQuery, GetCheckPointUserAttemptQueryVariables>(GetCheckPointUserAttemptQueryDocument, options);
      }
export function useGetCheckPointUserAttemptQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckPointUserAttemptQuery, GetCheckPointUserAttemptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCheckPointUserAttemptQuery, GetCheckPointUserAttemptQueryVariables>(GetCheckPointUserAttemptQueryDocument, options);
        }
export function useGetCheckPointUserAttemptQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCheckPointUserAttemptQuery, GetCheckPointUserAttemptQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCheckPointUserAttemptQuery, GetCheckPointUserAttemptQueryVariables>(GetCheckPointUserAttemptQueryDocument, options);
        }
export type GetCheckPointUserAttemptQueryHookResult = ReturnType<typeof useGetCheckPointUserAttemptQuery>;
export type GetCheckPointUserAttemptQueryLazyQueryHookResult = ReturnType<typeof useGetCheckPointUserAttemptQueryLazyQuery>;
export type GetCheckPointUserAttemptQuerySuspenseQueryHookResult = ReturnType<typeof useGetCheckPointUserAttemptQuerySuspenseQuery>;
export type GetCheckPointUserAttemptQueryQueryResult = Apollo.QueryResult<GetCheckPointUserAttemptQuery, GetCheckPointUserAttemptQueryVariables>;
export const GetUserAttemptResultDocument = gql`
    query GetUserAttemptResult($id: uuid!) {
  userAttempt: get_info_attempt(userAttemptId: $id) {
    id
    executionTime: execution_time
    submitted_at
    rankPercent: rank_percent
    totalScore: total_score
    totalMaxScore: total_max_score
    challenge {
      id
      code
      name
    }
    learnerProfile {
      id
      displayName: display_name
    }
    checkPoint: check_point {
      id
    }
    testLevel: test_level {
      id
      type
    }
  }
}
    `;

/**
 * __useGetUserAttemptResult__
 *
 * To run a query within a React component, call `useGetUserAttemptResult` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAttemptResult` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAttemptResult({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserAttemptResult(baseOptions: Apollo.QueryHookOptions<GetUserAttemptResult, GetUserAttemptResultVariables> & ({ variables: GetUserAttemptResultVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAttemptResult, GetUserAttemptResultVariables>(GetUserAttemptResultDocument, options);
      }
export function useGetUserAttemptResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAttemptResult, GetUserAttemptResultVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAttemptResult, GetUserAttemptResultVariables>(GetUserAttemptResultDocument, options);
        }
export function useGetUserAttemptResultSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserAttemptResult, GetUserAttemptResultVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserAttemptResult, GetUserAttemptResultVariables>(GetUserAttemptResultDocument, options);
        }
export type GetUserAttemptResultHookResult = ReturnType<typeof useGetUserAttemptResult>;
export type GetUserAttemptResultLazyQueryHookResult = ReturnType<typeof useGetUserAttemptResultLazyQuery>;
export type GetUserAttemptResultSuspenseQueryHookResult = ReturnType<typeof useGetUserAttemptResultSuspenseQuery>;
export type GetUserAttemptResultQueryResult = Apollo.QueryResult<GetUserAttemptResult, GetUserAttemptResultVariables>;
export const ScoreRankQueryDocument = gql`
    query ScoreRankQuery($id: String!) {
  studentReadingListeningAttempt(id: $id) {
    testResult {
      value
    }
  }
}
    `;

/**
 * __useScoreRankQuery__
 *
 * To run a query within a React component, call `useScoreRankQuery` and pass it any options that fit your needs.
 * When your component renders, `useScoreRankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScoreRankQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScoreRankQuery(baseOptions: Apollo.QueryHookOptions<ScoreRankQuery, ScoreRankQueryVariables> & ({ variables: ScoreRankQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScoreRankQuery, ScoreRankQueryVariables>(ScoreRankQueryDocument, options);
      }
export function useScoreRankQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScoreRankQuery, ScoreRankQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScoreRankQuery, ScoreRankQueryVariables>(ScoreRankQueryDocument, options);
        }
export function useScoreRankQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ScoreRankQuery, ScoreRankQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ScoreRankQuery, ScoreRankQueryVariables>(ScoreRankQueryDocument, options);
        }
export type ScoreRankQueryHookResult = ReturnType<typeof useScoreRankQuery>;
export type ScoreRankQueryLazyQueryHookResult = ReturnType<typeof useScoreRankQueryLazyQuery>;
export type ScoreRankQuerySuspenseQueryHookResult = ReturnType<typeof useScoreRankQuerySuspenseQuery>;
export type ScoreRankQueryQueryResult = Apollo.QueryResult<ScoreRankQuery, ScoreRankQueryVariables>;
export const TestLevelChallengeDocument = gql`
    query testLevelChallenge {
  testLevels: test_levels(order_by: {order: asc}, limit: 10) {
    id
    name
    slug
  }
}
    `;

/**
 * __useTestLevelChallenge__
 *
 * To run a query within a React component, call `useTestLevelChallenge` and pass it any options that fit your needs.
 * When your component renders, `useTestLevelChallenge` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestLevelChallenge({
 *   variables: {
 *   },
 * });
 */
export function useTestLevelChallenge(baseOptions?: Apollo.QueryHookOptions<TestLevelChallenge, TestLevelChallengeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestLevelChallenge, TestLevelChallengeVariables>(TestLevelChallengeDocument, options);
      }
export function useTestLevelChallengeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestLevelChallenge, TestLevelChallengeVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestLevelChallenge, TestLevelChallengeVariables>(TestLevelChallengeDocument, options);
        }
export function useTestLevelChallengeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TestLevelChallenge, TestLevelChallengeVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TestLevelChallenge, TestLevelChallengeVariables>(TestLevelChallengeDocument, options);
        }
export type TestLevelChallengeHookResult = ReturnType<typeof useTestLevelChallenge>;
export type TestLevelChallengeLazyQueryHookResult = ReturnType<typeof useTestLevelChallengeLazyQuery>;
export type TestLevelChallengeSuspenseQueryHookResult = ReturnType<typeof useTestLevelChallengeSuspenseQuery>;
export type TestLevelChallengeQueryResult = Apollo.QueryResult<TestLevelChallenge, TestLevelChallengeVariables>;
export const GetCheckPointChallengeDocument = gql`
    query GetCheckPointChallenge($where: check_points_bool_exp, $limit: Int, $offset: Int) {
  check_points(
    where: $where
    order_by: {type: asc}
    limit: $limit
    offset: $offset
  ) {
    id
    name
    name_en
    type
    order
    is_premium
  }
}
    `;

/**
 * __useGetCheckPointChallenge__
 *
 * To run a query within a React component, call `useGetCheckPointChallenge` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckPointChallenge` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckPointChallenge({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetCheckPointChallenge(baseOptions?: Apollo.QueryHookOptions<GetCheckPointChallenge, GetCheckPointChallengeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCheckPointChallenge, GetCheckPointChallengeVariables>(GetCheckPointChallengeDocument, options);
      }
export function useGetCheckPointChallengeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckPointChallenge, GetCheckPointChallengeVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCheckPointChallenge, GetCheckPointChallengeVariables>(GetCheckPointChallengeDocument, options);
        }
export function useGetCheckPointChallengeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCheckPointChallenge, GetCheckPointChallengeVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCheckPointChallenge, GetCheckPointChallengeVariables>(GetCheckPointChallengeDocument, options);
        }
export type GetCheckPointChallengeHookResult = ReturnType<typeof useGetCheckPointChallenge>;
export type GetCheckPointChallengeLazyQueryHookResult = ReturnType<typeof useGetCheckPointChallengeLazyQuery>;
export type GetCheckPointChallengeSuspenseQueryHookResult = ReturnType<typeof useGetCheckPointChallengeSuspenseQuery>;
export type GetCheckPointChallengeQueryResult = Apollo.QueryResult<GetCheckPointChallenge, GetCheckPointChallengeVariables>;
export const GetUserCanCreateProfileDocument = gql`
    query getUserCanCreateProfile($userId: Int!) {
  userCanCreateProfile(userId: $userId) {
    canCreateProfile
  }
}
    `;

/**
 * __useGetUserCanCreateProfile__
 *
 * To run a query within a React component, call `useGetUserCanCreateProfile` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCanCreateProfile` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCanCreateProfile({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserCanCreateProfile(baseOptions: Apollo.QueryHookOptions<GetUserCanCreateProfile, GetUserCanCreateProfileVariables> & ({ variables: GetUserCanCreateProfileVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCanCreateProfile, GetUserCanCreateProfileVariables>(GetUserCanCreateProfileDocument, options);
      }
export function useGetUserCanCreateProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCanCreateProfile, GetUserCanCreateProfileVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCanCreateProfile, GetUserCanCreateProfileVariables>(GetUserCanCreateProfileDocument, options);
        }
export function useGetUserCanCreateProfileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserCanCreateProfile, GetUserCanCreateProfileVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserCanCreateProfile, GetUserCanCreateProfileVariables>(GetUserCanCreateProfileDocument, options);
        }
export type GetUserCanCreateProfileHookResult = ReturnType<typeof useGetUserCanCreateProfile>;
export type GetUserCanCreateProfileLazyQueryHookResult = ReturnType<typeof useGetUserCanCreateProfileLazyQuery>;
export type GetUserCanCreateProfileSuspenseQueryHookResult = ReturnType<typeof useGetUserCanCreateProfileSuspenseQuery>;
export type GetUserCanCreateProfileQueryResult = Apollo.QueryResult<GetUserCanCreateProfile, GetUserCanCreateProfileVariables>;
export const GetChallengeByCodeQueryDocument = gql`
    query GetChallengeByCodeQuery($code: String!) {
  challenges(where: {code: {_eq: $code}}) {
    id
    name
    code
    isMixed: is_mixed
    startAt: start_at
    endAt: end_at
    learnerProfile: learner_profile {
      id
    }
    checkPoint: check_point {
      id
      testLevel: test_level {
        id
        name
      }
    }
    settings
  }
}
    `;

/**
 * __useGetChallengeByCodeQuery__
 *
 * To run a query within a React component, call `useGetChallengeByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengeByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengeByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetChallengeByCodeQuery(baseOptions: Apollo.QueryHookOptions<GetChallengeByCodeQuery, GetChallengeByCodeQueryVariables> & ({ variables: GetChallengeByCodeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChallengeByCodeQuery, GetChallengeByCodeQueryVariables>(GetChallengeByCodeQueryDocument, options);
      }
export function useGetChallengeByCodeQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChallengeByCodeQuery, GetChallengeByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChallengeByCodeQuery, GetChallengeByCodeQueryVariables>(GetChallengeByCodeQueryDocument, options);
        }
export function useGetChallengeByCodeQuerySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetChallengeByCodeQuery, GetChallengeByCodeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetChallengeByCodeQuery, GetChallengeByCodeQueryVariables>(GetChallengeByCodeQueryDocument, options);
        }
export type GetChallengeByCodeQueryHookResult = ReturnType<typeof useGetChallengeByCodeQuery>;
export type GetChallengeByCodeQueryLazyQueryHookResult = ReturnType<typeof useGetChallengeByCodeQueryLazyQuery>;
export type GetChallengeByCodeQuerySuspenseQueryHookResult = ReturnType<typeof useGetChallengeByCodeQuerySuspenseQuery>;
export type GetChallengeByCodeQueryQueryResult = Apollo.QueryResult<GetChallengeByCodeQuery, GetChallengeByCodeQueryVariables>;
export const CheckChallengeExtendsDocument = gql`
    query checkChallengeExtends($where: challenges_bool_exp) {
  challenges(where: $where) {
    is_limit
    is_hidden_name
    id
  }
}
    `;

/**
 * __useCheckChallengeExtends__
 *
 * To run a query within a React component, call `useCheckChallengeExtends` and pass it any options that fit your needs.
 * When your component renders, `useCheckChallengeExtends` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckChallengeExtends({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCheckChallengeExtends(baseOptions?: Apollo.QueryHookOptions<CheckChallengeExtends, CheckChallengeExtendsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckChallengeExtends, CheckChallengeExtendsVariables>(CheckChallengeExtendsDocument, options);
      }
export function useCheckChallengeExtendsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckChallengeExtends, CheckChallengeExtendsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckChallengeExtends, CheckChallengeExtendsVariables>(CheckChallengeExtendsDocument, options);
        }
export function useCheckChallengeExtendsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckChallengeExtends, CheckChallengeExtendsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckChallengeExtends, CheckChallengeExtendsVariables>(CheckChallengeExtendsDocument, options);
        }
export type CheckChallengeExtendsHookResult = ReturnType<typeof useCheckChallengeExtends>;
export type CheckChallengeExtendsLazyQueryHookResult = ReturnType<typeof useCheckChallengeExtendsLazyQuery>;
export type CheckChallengeExtendsSuspenseQueryHookResult = ReturnType<typeof useCheckChallengeExtendsSuspenseQuery>;
export type CheckChallengeExtendsQueryResult = Apollo.QueryResult<CheckChallengeExtends, CheckChallengeExtendsVariables>;
export const GetLastCheckPointForReportDocument = gql`
    query getLastCheckPointForReport($where: user_attempts_bool_exp) {
  user_attempts(where: $where, order_by: {created_at: desc}, limit: 1) {
    id
    check_point {
      name
      name_en
    }
    __typename
  }
}
    `;

/**
 * __useGetLastCheckPointForReport__
 *
 * To run a query within a React component, call `useGetLastCheckPointForReport` and pass it any options that fit your needs.
 * When your component renders, `useGetLastCheckPointForReport` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastCheckPointForReport({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLastCheckPointForReport(baseOptions?: Apollo.QueryHookOptions<GetLastCheckPointForReport, GetLastCheckPointForReportVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLastCheckPointForReport, GetLastCheckPointForReportVariables>(GetLastCheckPointForReportDocument, options);
      }
export function useGetLastCheckPointForReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLastCheckPointForReport, GetLastCheckPointForReportVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLastCheckPointForReport, GetLastCheckPointForReportVariables>(GetLastCheckPointForReportDocument, options);
        }
export function useGetLastCheckPointForReportSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLastCheckPointForReport, GetLastCheckPointForReportVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLastCheckPointForReport, GetLastCheckPointForReportVariables>(GetLastCheckPointForReportDocument, options);
        }
export type GetLastCheckPointForReportHookResult = ReturnType<typeof useGetLastCheckPointForReport>;
export type GetLastCheckPointForReportLazyQueryHookResult = ReturnType<typeof useGetLastCheckPointForReportLazyQuery>;
export type GetLastCheckPointForReportSuspenseQueryHookResult = ReturnType<typeof useGetLastCheckPointForReportSuspenseQuery>;
export type GetLastCheckPointForReportQueryResult = Apollo.QueryResult<GetLastCheckPointForReport, GetLastCheckPointForReportVariables>;
export const GetReview2023Document = gql`
    query getReview2023($where: review_2023_bool_exp) {
  review_2023(where: $where) {
    accuracy
    total_done
    total_time
    total_grade
    conquest
    score
    __typename
  }
}
    `;

/**
 * __useGetReview2023__
 *
 * To run a query within a React component, call `useGetReview2023` and pass it any options that fit your needs.
 * When your component renders, `useGetReview2023` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReview2023({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetReview2023(baseOptions?: Apollo.QueryHookOptions<GetReview2023, GetReview2023Variables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReview2023, GetReview2023Variables>(GetReview2023Document, options);
      }
export function useGetReview2023LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReview2023, GetReview2023Variables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReview2023, GetReview2023Variables>(GetReview2023Document, options);
        }
export function useGetReview2023SuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetReview2023, GetReview2023Variables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReview2023, GetReview2023Variables>(GetReview2023Document, options);
        }
export type GetReview2023HookResult = ReturnType<typeof useGetReview2023>;
export type GetReview2023LazyQueryHookResult = ReturnType<typeof useGetReview2023LazyQuery>;
export type GetReview2023SuspenseQueryHookResult = ReturnType<typeof useGetReview2023SuspenseQuery>;
export type GetReview2023QueryResult = Apollo.QueryResult<GetReview2023, GetReview2023Variables>;
export const GetReview2024Document = gql`
    query getReview2024($where: review_2024_bool_exp) {
  review_2024(where: $where) {
    accuracy
    total_done
    total_time
    total_grade
    conquest
    score
    __typename
  }
}
    `;

/**
 * __useGetReview2024__
 *
 * To run a query within a React component, call `useGetReview2024` and pass it any options that fit your needs.
 * When your component renders, `useGetReview2024` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReview2024({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetReview2024(baseOptions?: Apollo.QueryHookOptions<GetReview2024, GetReview2024Variables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReview2024, GetReview2024Variables>(GetReview2024Document, options);
      }
export function useGetReview2024LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReview2024, GetReview2024Variables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReview2024, GetReview2024Variables>(GetReview2024Document, options);
        }
export function useGetReview2024SuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetReview2024, GetReview2024Variables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReview2024, GetReview2024Variables>(GetReview2024Document, options);
        }
export type GetReview2024HookResult = ReturnType<typeof useGetReview2024>;
export type GetReview2024LazyQueryHookResult = ReturnType<typeof useGetReview2024LazyQuery>;
export type GetReview2024SuspenseQueryHookResult = ReturnType<typeof useGetReview2024SuspenseQuery>;
export type GetReview2024QueryResult = Apollo.QueryResult<GetReview2024, GetReview2024Variables>;